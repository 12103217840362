import React, { useEffect, useState } from "react";
import Header from "./HomeComp/Header";
import { round } from "./redux/helpers/Math";
import { useSelector } from "react-redux";
import {
  N_getHarvest,
  N_getRefStaking,
  N_getStake,
  N_harvestRefStaking,
  N_setStake,
} from "./redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
import "./staking.css";
import "./stak.css";
import { IoCaretForwardOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";

function Staking(props) {
  // const [data, setData] = useState();
  const [balance, setBalance] = useState(0);
  const [dataThreeMonth, setThreeMonthData] = useState();
  const [dataSixMonth, setSixMonthData] = useState();
  const [dataTwelveMonth, setTwelveMonthData] = useState();
  const [onedtbxValueThreeMonth, setonedtbxValueThreeMonth] = useState(0);
  const [onedtbxValueSixMonth, setonedtbxValueSixMonth] = useState(0);
  const [onedtbxValueTwelveMonth, setonedtbxValueTwelveMonth] = useState(0);
  const invest_type1 = "DTBX";
  const [selectedTime, setSelectedTime] = useState(3);
  const [totalLockedValue, setTotalLockedValue] = useState(0);
  const [totalInterestEarned, setTotalInterestEarned] = useState(0);
  const [totalRefInterestEarned, setTotalRefInterestEarned] = useState(0);
  const [showConfirmationModal3, setShowConfirmationModal3] = useState(false);
  const [showConfirmationModal6, setShowConfirmationModal6] = useState(false);
  const [showConfirmationModal12, setShowConfirmationModal12] = useState(false);
  const [dtbxinrprice, setdtbxprice] = useState(null)

  const [refUser, setRefUser] = useState(null);

  const handleTimeChange = (selectedOption) => {
    setSelectedTime(selectedOption);
  };

  const { user, isLoggedIn } = useSelector((state) => state.AuthReducer);
  let { coins, wallet, vhl_data } = useSelector((state) => state.coinDBReducer);
  let { webData } = useSelector((state) => state.websiteDBReducer);

  let coins_data = Object.values(coins);
  // const dtbxprice =
  //   coins_data && coins_data.find((coin) => coin.symbol == "DTBX");
  // const dtbxinrprice = round(
  //   dtbxprice && dtbxprice.inr_price ? dtbxprice.inr_price : 0
  // );

  useEffect(() => {
    if (vhl_data) {
      const keys = Object.keys(vhl_data)

      // const matchingKey = keys.find(key => key === props.match.params.id);
      const matchingKey = "dtbx-inr"

      // Check if a matching key is found
      if (matchingKey) {
        // Get the data corresponding to the matching key
        const matchingData = vhl_data[matchingKey];

        if (matchingData) {
          setdtbxprice(matchingData.raw_price);
        }
      }
    }
  }, [vhl_data]);

  useEffect(() => {
    if (user?.params ? user.params.token : user.token) startStaking();
  }, []);

  function startStaking() {
    const token = user?.params ? user.params.token : user.token;

    N_getStake(token, 90).then((d) => {
      if (d.status === 200 || d.status === 300) {
        setThreeMonthData(d.result);
      }
    });

    N_getStake(token, 180).then((d) => {
      if (d.status === 200 || d.status === 300) {
        setSixMonthData(d.result);
      }
    });

    N_getStake(token, 365).then((d) => {
      if (d.status === 200 || d.status === 300) {
        setTwelveMonthData(d.result);
      }
    });
  }

  useEffect(() => {
    const dtbxData = wallet.find((obj) => obj.symbol === invest_type1);

    if (dtbxData) {
      setBalance(dtbxData.balance);
    }
  }, [wallet]);

  useEffect(() => {
    if (dataThreeMonth && dataSixMonth && dataTwelveMonth) {
      const threeMonthLockedValue = dataThreeMonth
        ? round(dataThreeMonth.total)
        : 0;
      const SixMonthLockedValue = dataSixMonth ? round(dataSixMonth.total) : 0;
      const TwelveMonthLockedValue = dataTwelveMonth
        ? round(dataTwelveMonth.total)
        : 0;

      const totalLockedValue =
        threeMonthLockedValue + SixMonthLockedValue + TwelveMonthLockedValue;

      const threeMonthStakeInterestEarning = dataThreeMonth
        ? round(dataThreeMonth.stake_interest_earned)
        : 0;

      const SixMonthStakeInterestEarning = dataSixMonth
        ? round(dataSixMonth.stake_interest_earned)
        : 0;

      const TwelveMonthStakeInterestEarning = dataTwelveMonth
        ? round(dataTwelveMonth.stake_interest_earned)
        : 0;

      const totalInterestEarned =
        threeMonthStakeInterestEarning +
        SixMonthStakeInterestEarning +
        TwelveMonthStakeInterestEarning;

      setTotalLockedValue(totalLockedValue);
      setTotalInterestEarned(totalInterestEarned.toFixed(6));
    }
  }, [dataThreeMonth, dataSixMonth, dataTwelveMonth]);

  useEffect(() => {
    const token = user?.params ? user.params.token : user.token;

    N_getRefStaking(token).then((d) => {
      if (d.status === 200) {
        // console.log(d.result);
        setRefUser(d.result);
        const TotalstakeInterestEarned = d.result.reduce(
          (sum, obj) => sum + obj.stakeInterestEarned,
          0
        );
        setTotalRefInterestEarned(round(TotalstakeInterestEarned).toFixed(6));
      }
    });
  }, []);

  return (
    <>
      <div className="application">
        <Helmet>
          <meta
            name="description"
            content="Explore the market for seamless and secure crypto trading. Dive
into the world of Crypto Trading and elevate your trading experience. "
          />
          <title>Unlock FRX Staking Rewards in Staking Pool</title>
        </Helmet>
      </div>

      <Header {...props} />

      <div className="page content">
        <div className="d-flex justify-content-end mx-4">
          <NavLink to="/staking-history">
            <button className="btn btn-primary d-flex align-items-center">
              <span>History</span>
              <IoCaretForwardOutline size={25} className="mr-2" />
            </button>
          </NavLink>
        </div>
        <div className="staking-view background-mode">
          <div className="stak-container">
            <div className="banner-stake">
              <div className="banner-stake--container">
                <div className="banner-stake__title">
                  <div className="bg-shadow">
                    <h3>DTBX Farm</h3>
                    <div className="banner-stake__desc">Stake to earn more</div>
                  </div>
                </div>
                <div className="banner-stake__right">
                  <div className="banner-stake__total-lock">
                    <div className="label">Total Value Locked</div>
                    <div className="value">
                      <span>{totalLockedValue && totalLockedValue}</span>
                    </div>
                  </div>

                  <div className="banner-stake__price-wana">
                    <div className="label">Current DTBX Price </div>
                    <div className="value">
                      <span>{round(dtbxinrprice)} ₹</span>
                    </div>
                  </div>

                  <div className="banner-stake__total-lock">
                    <div className="label" style={{ whiteSpace: "nowrap" }}>
                      Staking Interest Total
                    </div>
                    <div className="value">
                      <span>{totalInterestEarned && totalInterestEarned}</span>
                    </div>
                  </div>

                  <div className="banner-stake__total-lock">
                    <div className="label" style={{ whiteSpace: "nowrap" }}>
                      Staking Referral Earnings
                    </div>
                    <div className="value">
                      <span>
                        {totalRefInterestEarned && totalRefInterestEarned}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="list-staking">
              <div
                className="row ant-row-center"
                style={{
                  rowGap: "30px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  className="col col-md-12 col-sm-12 banner-stake"
                  style={{ height: "100px", background: "#60a7ab" }}
                >
                  <div className="banner-stake--container">
                    <div className="">
                      <div className="bg-shadow">
                        <h3>DTBX Farm Minimum Stake 1000 DTBX</h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ----------- Three Month -------- */}
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="item-staking">
                    <div className="header-item">
                      <div className="logo-token">
                        <img src="/favicon.ico" alt="logo-token" />
                      </div>
                      {/* <div className="info-pool textmode">
                        <h2 className="textmode">FARM</h2>(
                        {"90 days locked reward"})
                      </div> */}
                    </div>
                    <div className="content-item">
                      <div className="show-apr textmode">
                        <div
                          className="title-apr"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Staking Period
                        </div>
                        <div className="apr">
                          <span>90 Days</span>
                        </div>
                      </div>
                      <div className="show-apr textmode">
                        <div
                          className="title-apr"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Staking Interest
                        </div>
                        <div className="apr">
                          <span>
                            {webData?.stake
                              ? webData.stake[0].threeMonth_stack_percentage * 3
                              : 0}
                            %
                          </span>
                        </div>
                      </div>
                      {/* <div className="show-apr textmode">
                        <div
                          className="title-apr"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Referral Interest
                        </div>
                        <div className="apr">
                          <span>
                            {webData?.stake
                              ? webData.stake[0].threeMonth_stack_refPercentage
                              : 0}
                            % Monthly
                          </span>
                        </div>
                      </div> */}
                      <div class="staked-lp">
                        <span class="blur-text textmode">Staking Amount: </span>
                        <span
                          class="textmode staked-lp-amount"
                          style={{ cursor: "pointer" }}
                        >
                          {dataThreeMonth?.total
                            ? round(dataThreeMonth.total)
                            : 0}
                        </span>
                      </div>
                      {/* <div class="staked-lp">
                        <span class="blur-text textmode">
                          Date of Maturity:{" "}
                        </span>
                        <span
                          class="textmode staked-lp-amount"
                          style={{ cursor: "pointer" }}
                        >
                          {dataThreeMonth?.timeEx
                            ? new Date(
                                dataThreeMonth?.timeEx
                              ).toLocaleDateString()
                            : 0}
                        </span>
                      </div> */}
                      <div className="wrap-amount-stake wrap-earn textmode">
                        {/* <div className="title-amount-stake textmode">
                          FRX
                          <span className="badge">Interest EARNED</span>
                        </div> */}
                        <span class="blur-text textmode">Earned Amount: </span>
                        <div className="token-earn">
                          <span className="textmode">
                            {dataThreeMonth?.stake_interest_earned
                              ? round(dataThreeMonth.stake_interest_earned)
                              : 0}
                          </span>
                        </div>
                      </div>

                      <div className="wrap-amount-stake wrap-earn textmode">
                        {/* <div className="title-amount-stake textmode">
                          FRX
                          <span className="badge">Interest EARNED</span>
                        </div> */}
                        <span class="blur-text textmode">
                          Withdraw Amount:{" "}
                        </span>
                        <div className="token-earn">
                          <span className="textmode">
                            {dataThreeMonth?.one_daily_ry
                              ? round(dataThreeMonth.one_daily_ry)
                              : 0}
                          </span>
                        </div>
                      </div>
                      <div className="wrap-amount-stake wrap-earn textmode">
                        {/* <div className="title-amount-stake textmode">
                          FRX
                          <span className="badge">Interest EARNED</span>
                        </div> */}
                        <span class="blur-text textmode">
                          Upcoming Interest:{" "}
                        </span>
                        <div className="token-earn">
                          <span className="textmode">
                            {dataThreeMonth?.remainingInterest
                              ? round(dataThreeMonth.remainingInterest)
                              : 0}
                          </span>
                        </div>
                      </div>
                      <div className="wrap-amount-stake wrap-earn textmode">
                        {/* <div className="title-amount-stake textmode">
                          FRX
                          <span className="badge">Earnings at Maturity</span>
                        </div> */}
                        <span class="blur-text textmode">
                          Maturity Earning:{" "}
                        </span>
                        <div className="token-earn">
                          <span className="textmode">
                            {dataThreeMonth?.MaturityEarning
                              ? round(dataThreeMonth.MaturityEarning)
                              : 0}
                          </span>
                        </div>
                      </div>
                      <div className="wrap-detail-and-harvest textmode">
                        <div className="btn-show-detail">Withdraw Amount</div>
                        <div className="harvest">
                          <button
                            disabled={
                              dataThreeMonth?.one_daily_ry > 0 ? "" : "disabled"
                            }
                            type="button"
                            className="ant-btn"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              const token = user?.params
                                ? user.params.token
                                : user.token;
                              const invest_time = 90; // days
                              if (dataThreeMonth.one_daily_ry > 0) {
                                N_getHarvest(
                                  token,
                                  invest_time,
                                  round(dataThreeMonth.one_daily_ry)
                                ).then((d) => {
                                  if (d.status === 200) {
                                    N_getStake(token).then((d) => {
                                      if (d.status === 200) {
                                        setThreeMonthData(d.result);
                                      }
                                    });
                                    NotificationManager.success(d.message);
                                  } else {
                                    NotificationManager.error(d.message);
                                  }
                                });
                              } else {
                                NotificationManager.info("You Have not Earn");
                              }
                            }}
                          >
                            <span>Withdraw</span>
                          </button>
                        </div>
                      </div>
                      <div class="wrap-amount-stake textmode">
                        <div class="input-stake-withdraw">
                          <div class="balance-lp-and-staked">
                            <div class="balance-lp">
                              <span class="blur-text textmode">
                                Available {invest_type1}:{" "}
                              </span>
                              <span
                                class="textmode balance-lp-amount"
                                style={{ cursor: "pointer" }}
                              >
                                {balance}
                              </span>
                            </div>
                          </div>
                          <div class="input-amount">
                            <div class="input-amount-unstakek">
                              <div class="btn-max">More Deposit</div>
                            </div>
                            <div class="input-amount-stake">
                              <div class="ant-input-number ant-input-number-lg ant-input-number-borderless">
                                <div class="ant-input-number-input-wrap">
                                  <input
                                    autoComplete="off"
                                    role="spinbutton"
                                    ariaValuemin="0.0001"
                                    step="1"
                                    placeholder="Enter Amount"
                                    value={onedtbxValueThreeMonth}
                                    class="ant-input-number-input"
                                    onChange={(e) => {
                                      setonedtbxValueThreeMonth(
                                        e.target.value
                                          .replace(/[^0-9.]/g, "")
                                          .replace(/(\..*?)\..*/g, "$1")
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div class="btn-max">{invest_type1}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {!isLoggedIn ? (
                        <button
                          type="button"
                          style={{ cursor: "pointer" }}
                          className="ant-btn ant-btn-primary ant-btn-lg btn-stake"
                          onClick={() => {
                            props.history.replace("/login");
                          }}
                        >
                          <span>Login</span>
                        </button>
                      ) : (
                        <>
                          <button
                            type="button"
                            style={{ cursor: "pointer" }}
                            id="first_stake"
                            className="btn btn-success w-100"
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                onedtbxValueThreeMonth &&
                                onedtbxValueThreeMonth > 999
                              ) {
                                setShowConfirmationModal3(true);
                              } else {
                                NotificationManager.error(
                                  "Please Enter the Stake Amount Minimum 1000"
                                );
                              }
                            }}
                          >
                            Stake
                          </button>

                          {showConfirmationModal3 && (
                            <Modal
                              centered
                              show={showConfirmationModal3}
                              onHide={() => setShowConfirmationModal3(false)}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title className="text-center h6">
                                  <span>Stake Confirmation</span>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <p className="text-center">
                                  Are you sure you want to stake ?
                                </p>
                              </Modal.Body>
                              <Modal.Footer>
                                <div className="row">
                                  <div className="col-6">
                                    <Button
                                      variant="secondary"
                                      block
                                      onClick={() =>
                                        setShowConfirmationModal3(false)
                                      }
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                  <div className="col-6">
                                    <Button
                                      variant="success"
                                      block
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (balance) {
                                          N_setStake(
                                            user?.params
                                              ? user.params.token
                                              : user.token,
                                            round(onedtbxValueThreeMonth),
                                            90
                                          ).then((d) => {
                                            if (d.status === 200) {
                                              N_getStake(
                                                user?.params
                                                  ? user.params.token
                                                  : user.token
                                              ).then((d) => {
                                                if (d.status === 200) {
                                                  setThreeMonthData(d.result);
                                                }
                                              });
                                              NotificationManager.success(
                                                d.message
                                              );
                                              setTimeout(() => {
                                                window.location.reload();
                                              }, 2000);
                                            } else {
                                              NotificationManager.error(
                                                d.message
                                              );
                                            }
                                          });
                                        } else {
                                          NotificationManager.info(
                                            "Your Amount too Low"
                                          );
                                        }
                                      }}
                                    >
                                      Confirm
                                    </Button>
                                  </div>
                                </div>
                              </Modal.Footer>
                            </Modal>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {/* --------Six Month ---------- */}
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="item-staking">
                    <div className="header-item">
                      <div className="logo-token">
                        <img src="/favicon.ico" alt="logo-token" />
                      </div>
                      {/* <div className="info-pool textmode">
                        <h2 className="textmode">DTBX FARM</h2>(180 days locked
                        reward)
                      </div> */}
                    </div>
                    <div className="content-item">
                      <div className="show-apr textmode">
                        <div
                          className="title-apr"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Staking Period
                        </div>
                        <div className="apr">
                          <span>180 Days</span>
                        </div>
                      </div>
                      <div className="show-apr textmode">
                        <div
                          className="title-apr"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Staking Interest
                        </div>
                        <div className="apr">
                          <span>
                            {webData?.stake
                              ? webData.stake[0].SixMonth_stack_percentage * 6
                              : 0}
                            %
                          </span>
                        </div>
                      </div>
                      {/* <div className="show-apr textmode">
                        <div
                          className="title-apr"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Referral Interest
                        </div>
                        <div className="apr">
                          <span>
                            {webData?.stake
                              ? webData.stake[0].SixMonth_stack_refPercentage
                              : 0}
                            % Monthly
                          </span>
                        </div>
                      </div> */}
                      <div class="staked-lp">
                        <span class="blur-text textmode">Staking Amount: </span>
                        <span
                          class="textmode staked-lp-amount"
                          style={{ cursor: "pointer" }}
                        >
                          {dataSixMonth?.total ? round(dataSixMonth.total) : 0}
                        </span>
                      </div>
                      {/* <div class="staked-lp">
                        <span class="blur-text textmode">
                          Date of Maturity:{" "}
                        </span>
                        <span
                          class="textmode staked-lp-amount"
                          style={{ cursor: "pointer" }}
                        >
                          {dataSixMonth?.timeEx
                            ? new Date(
                                dataSixMonth?.timeEx
                              ).toLocaleDateString()
                            : 0}
                        </span>
                      </div> */}
                      <div className="wrap-amount-stake wrap-earn textmode">
                        {/* <div className="title-amount-stake textmode">
                          DTBX
                          <span className="badge">Interest EARNED</span>
                        </div> */}
                        <span class="blur-text textmode">Earned Amount: </span>

                        <div className="token-earn">
                          <span className="textmode">
                            {dataSixMonth?.stake_interest_earned
                              ? round(dataSixMonth.stake_interest_earned)
                              : 0}
                          </span>
                        </div>
                      </div>
                      <div className="wrap-amount-stake wrap-earn textmode">
                        {/* <div className="title-amount-stake textmode">
                          DTBX
                          <span className="badge">Interest EARNED</span>
                        </div> */}
                        <span class="blur-text textmode">
                          Withdraw Amount:{" "}
                        </span>

                        <div className="token-earn">
                          <span className="textmode">
                            {dataSixMonth?.one_daily_ry
                              ? round(dataSixMonth.one_daily_ry)
                              : 0}
                          </span>
                        </div>
                      </div>
                      <div className="wrap-amount-stake wrap-earn textmode">
                        {/* <div className="title-amount-stake textmode">
                          DTBX
                          <span className="badge">Interest EARNED</span>
                        </div> */}
                        <span class="blur-text textmode">
                          Upcoming Interest: :{" "}
                        </span>

                        <div className="token-earn">
                          <span className="textmode">
                            {dataSixMonth?.remainingInterest
                              ? round(dataSixMonth.remainingInterest)
                              : 0}
                          </span>
                        </div>
                      </div>
                      <div className="wrap-amount-stake wrap-earn textmode">
                        {/* <div className="title-amount-stake textmode">
                          DTBX
                          <span className="badge">Earnings at Maturity</span>
                        </div> */}
                        <span class="blur-text textmode">
                          Maturity Earning:{" "}
                        </span>
                        <div className="token-earn">
                          <span className="textmode">
                            {dataSixMonth?.MaturityEarning
                              ? round(dataSixMonth.MaturityEarning)
                              : 0}
                          </span>
                        </div>
                      </div>
                      <div className="wrap-detail-and-harvest textmode">
                        <div className="btn-show-detail">Withdraw Amount</div>
                        <div className="harvest">
                          <button
                            disabled={
                              dataSixMonth?.one_daily_ry > 0 ? "" : "disabled"
                            }
                            type="button"
                            className="ant-btn"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              const token = user?.params
                                ? user.params.token
                                : user.token;
                              const invest_time = 180; // days
                              if (dataSixMonth.one_daily_ry > 0) {
                                N_getHarvest(
                                  token,
                                  invest_time,
                                  round(dataSixMonth.one_daily_ry)
                                ).then((d) => {
                                  if (d.status === 200) {
                                    N_getStake(token).then((d) => {
                                      if (d.status === 200) {
                                        setSixMonthData(d.result);
                                      }
                                    });
                                    NotificationManager.success(d.message);
                                  } else {
                                    NotificationManager.error(d.message);
                                  }
                                });
                              } else {
                                NotificationManager.info("You Have not Earn");
                              }
                            }}
                          >
                            <span>Withdraw</span>
                          </button>
                        </div>
                      </div>
                      <div class="wrap-amount-stake textmode">
                        <div class="input-stake-withdraw">
                          <div class="balance-lp-and-staked">
                            <div class="balance-lp">
                              <span class="blur-text textmode">
                                Available {invest_type1}:{" "}
                              </span>
                              <span
                                class="textmode balance-lp-amount"
                                style={{ cursor: "pointer" }}
                              >
                                {balance}
                              </span>
                            </div>
                          </div>
                          <div class="input-amount">
                            <div class="input-amount-unstakek">
                              <div class="btn-max">More Deposit</div>
                            </div>
                            <div class="input-amount-stake">
                              <div class="ant-input-number ant-input-number-lg ant-input-number-borderless">
                                <div class="ant-input-number-input-wrap">
                                  <input
                                    autoComplete="off"
                                    role="spinbutton"
                                    ariaValuemin="0.0001"
                                    step="1"
                                    placeholder="Enter Amount"
                                    value={onedtbxValueSixMonth}
                                    class="ant-input-number-input"
                                    onChange={(e) => {
                                      setonedtbxValueSixMonth(
                                        e.target.value
                                          .replace(/[^0-9.]/g, "")
                                          .replace(/(\..*?)\..*/g, "$1")
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div class="btn-max">{invest_type1}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {!isLoggedIn ? (
                        <button
                          type="button"
                          style={{ cursor: "pointer" }}
                          className="ant-btn ant-btn-primary ant-btn-lg btn-stake"
                          onClick={() => {
                            props.history.replace("/login");
                          }}
                        >
                          <span>Login</span>
                        </button>
                      ) : (
                        <>
                          <button
                            type="button"
                            style={{ cursor: "pointer" }}
                            id="first_stake"
                            className="btn btn-success w-100"
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                onedtbxValueSixMonth &&
                                onedtbxValueSixMonth > 999
                              ) {
                                setShowConfirmationModal6(true);
                              } else {
                                NotificationManager.error(
                                  "Please Enter the Stake Amount Minimum 1000"
                                );
                              }
                            }}
                          >
                            <span>Stake</span>
                          </button>

                          {showConfirmationModal6 && (
                            <Modal
                              centered
                              show={showConfirmationModal6}
                              onHide={() => setShowConfirmationModal6(false)}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title className="text-center h6">
                                  <span>Stake Confirmation</span>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <p className="text-center">
                                  Are you sure you want to stake ?
                                </p>
                              </Modal.Body>
                              <Modal.Footer>
                                <div className="row">
                                  <div className="col-6">
                                    <Button
                                      variant="secondary"
                                      block
                                      onClick={() =>
                                        setShowConfirmationModal6(false)
                                      }
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                  <div className="col-6">
                                    <Button
                                      variant="success"
                                      block
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (balance) {
                                          N_setStake(
                                            user?.params
                                              ? user.params.token
                                              : user.token,
                                            round(onedtbxValueSixMonth),
                                            180
                                          ).then((d) => {
                                            if (d.status === 200) {
                                              N_getStake(
                                                user?.params
                                                  ? user.params.token
                                                  : user.token
                                              ).then((d) => {
                                                if (d.status === 200) {
                                                  setSixMonthData(d.result);
                                                }
                                              });
                                              NotificationManager.success(
                                                d.message
                                              );
                                              setTimeout(() => {
                                                window.location.reload();
                                              }, 2000);
                                            } else {
                                              NotificationManager.error(
                                                d.message
                                              );
                                            }
                                          });
                                        } else {
                                          NotificationManager.info(
                                            "Your Amount too Low"
                                          );
                                        }
                                      }}
                                    >
                                      Confirm
                                    </Button>
                                  </div>
                                </div>
                              </Modal.Footer>
                            </Modal>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {/* ----------------- Twelve Month --------------  */}
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="item-staking">
                    <div className="header-item">
                      <div className="logo-token">
                        <img src="/favicon.ico" alt="logo-token" />
                      </div>
                      {/* <div className="info-pool textmode">
                        <h2 className="textmode"> DTBX FARM</h2>(365 days locked
                        reward)
                      </div> */}
                    </div>
                    <div className="content-item">
                      <div className="show-apr textmode">
                        <div
                          className="title-apr"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Staking Period
                        </div>
                        <div className="apr">
                          <span>365 Days</span>
                        </div>
                      </div>
                      <div className="show-apr textmode">
                        <div
                          className="title-apr"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Staking Interest
                        </div>
                        <div className="apr">
                          <span>
                            {webData?.stake
                              ? webData.stake[0].TwelveMonth_stack_percentage *
                              12
                              : 0}
                            %
                          </span>
                        </div>
                      </div>
                      {/* <div className="show-apr textmode">
                        <div
                          className="title-apr"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Referral Interest
                        </div>
                        <div className="apr">
                          <span>
                            {webData?.stake
                              ? webData.stake[0].TwelveMonth_stack_refPercentage
                              : 0}
                            % Monthly
                          </span>
                        </div>
                      </div> */}
                      <div class="staked-lp">
                        <span class="blur-text textmode">Staking Amount: </span>
                        <span
                          class="textmode staked-lp-amount"
                          style={{ cursor: "pointer" }}
                        >
                          {dataTwelveMonth?.total
                            ? round(dataTwelveMonth.total)
                            : 0}
                        </span>
                      </div>
                      {/* <div class="staked-lp">
                        <span class="blur-text textmode">
                          Date of Maturity:{" "}
                        </span>
                        <span
                          class="textmode staked-lp-amount"
                          style={{ cursor: "pointer" }}
                        >
                          {dataTwelveMonth?.timeEx
                            ? new Date(
                                dataTwelveMonth.timeEx
                              ).toLocaleDateString()
                            : 0}
                        </span>
                      </div> */}
                      {/* <div className="show-earn textmode">
                        <div className="title-earn">Earn: </div>
                        <div className="token-earn">
                          <span className="textmode">DTBX</span>
                        </div>
                      </div> */}
                      <div className="wrap-amount-stake wrap-earn textmode">
                        {/* <div className="title-amount-stake textmode">
                          DTBX
                          <span className="badge">Interest EARNED</span>
                        </div> */}
                        <span class="blur-text textmode">Earned Amount: </span>
                        <div className="token-earn">
                          <span className="textmode">
                            {dataTwelveMonth?.stake_interest_earned
                              ? round(dataTwelveMonth.stake_interest_earned)
                              : 0}
                          </span>
                        </div>
                      </div>

                      <div className="wrap-amount-stake wrap-earn textmode">
                        {/* <div className="title-amount-stake textmode">
                          DTBX
                          <span className="badge">Interest EARNED</span>
                        </div> */}
                        <span class="blur-text textmode">
                          Withdraw Amount:{" "}
                        </span>
                        <div className="token-earn">
                          <span className="textmode">
                            {dataTwelveMonth?.one_daily_ry
                              ? round(dataTwelveMonth.one_daily_ry)
                              : 0}
                          </span>
                        </div>
                      </div>

                      <div className="wrap-amount-stake wrap-earn textmode">
                        {/* <div className="title-amount-stake textmode">
                          DTBX
                          <span className="badge">Interest EARNED</span>
                        </div> */}
                        <span class="blur-text textmode">
                          Upcoming Interest:{" "}
                        </span>
                        <div className="token-earn">
                          <span className="textmode">
                            {dataTwelveMonth?.remainingInterest
                              ? round(dataTwelveMonth.remainingInterest)
                              : 0}
                          </span>
                        </div>
                      </div>
                      <div className="wrap-amount-stake wrap-earn textmode">
                        {/* <div className="title-amount-stake textmode">
                          DTBX
                          <span className="badge">Earnings at Maturity</span>
                        </div> */}
                        <span class="blur-text textmode">
                          Maturity Earning:{" "}
                        </span>
                        <div className="token-earn">
                          <span className="textmode">
                            {dataTwelveMonth?.MaturityEarning
                              ? round(dataTwelveMonth.MaturityEarning)
                              : 0}
                          </span>
                        </div>
                      </div>
                      <div className="wrap-detail-and-harvest textmode">
                        <div className="btn-show-detail">Withdraw Amount</div>
                        <div className="harvest">
                          <button
                            disabled={
                              dataTwelveMonth?.one_daily_ry > 0
                                ? ""
                                : "disabled"
                            }
                            type="button"
                            className="ant-btn"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              const token = user?.params
                                ? user.params.token
                                : user.token;
                              const invest_time = 365; // days
                              if (dataTwelveMonth.one_daily_ry > 0) {
                                N_getHarvest(
                                  token,
                                  invest_time,
                                  round(dataTwelveMonth.one_daily_ry)
                                ).then((d) => {
                                  if (d.status === 200) {
                                    N_getStake(token).then((d) => {
                                      if (d.status === 200) {
                                        setTwelveMonthData(d.result);
                                      }
                                    });
                                    NotificationManager.success(d.message);
                                  } else {
                                    NotificationManager.error(d.message);
                                  }
                                });
                              } else {
                                NotificationManager.info("You Have not Earn");
                              }
                            }}
                          >
                            <span>Withdraw</span>
                          </button>
                        </div>
                      </div>
                      <div class="wrap-amount-stake textmode">
                        <div class="input-stake-withdraw">
                          <div class="balance-lp-and-staked">
                            <div class="balance-lp">
                              <span class="blur-text textmode">
                                Available {invest_type1}:{" "}
                              </span>
                              <span
                                class="textmode balance-lp-amount"
                                style={{ cursor: "pointer" }}
                              >
                                {balance}
                              </span>
                            </div>
                          </div>
                          <div class="input-amount">
                            <div class="input-amount-unstakek">
                              <div class="btn-max">More Deposit</div>
                            </div>
                            <div class="input-amount-stake">
                              <div class="ant-input-number ant-input-number-lg ant-input-number-borderless">
                                <div class="ant-input-number-input-wrap">
                                  <input
                                    autoComplete="off"
                                    role="spinbutton"
                                    ariaValuemin="0.0001"
                                    step="1"
                                    placeholder="Enter Amount"
                                    value={onedtbxValueTwelveMonth}
                                    class="ant-input-number-input"
                                    onChange={(e) => {
                                      setonedtbxValueTwelveMonth(
                                        e.target.value
                                          .replace(/[^0-9.]/g, "")
                                          .replace(/(\..*?)\..*/g, "$1")
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div class="btn-max">{invest_type1}</div>
                            </div>
                            {/* <div class="input-amount-unstakek">
                              <div class="btn-max">
                                {round(onedtbxValueTwelveMonth)}
                              </div>
                            </div> */}
                          </div>
                          {/* <div className="d-flex justify-content-start">
                            <div class="container mt-3 ">
                              <div class="border p-3 rounded bg-secondary">
                                <span class="font-weight-bold text-white">
                                  12 Months
                                </span>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      {!isLoggedIn ? (
                        <button
                          type="button"
                          style={{ cursor: "pointer" }}
                          className="ant-btn ant-btn-primary ant-btn-lg btn-stake"
                          onClick={() => {
                            props.history.replace("/login");
                          }}
                        >
                          <span>Login</span>
                        </button>
                      ) : (
                        <>
                          <button
                            type="button"
                            style={{ cursor: "pointer" }}
                            id="first_stake"
                            className="btn btn-success w-100"
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                onedtbxValueTwelveMonth &&
                                onedtbxValueTwelveMonth > 999
                              ) {
                                setShowConfirmationModal12(true);
                              } else {
                                NotificationManager.error(
                                  "Please Enter the Stake Amount Minimum 1000"
                                );
                              }
                            }}
                          >
                            <span>Stake</span>
                          </button>

                          {showConfirmationModal12 && (
                            <Modal
                              centered
                              show={showConfirmationModal12}
                              onHide={() => setShowConfirmationModal12(false)}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title className="text-center h6">
                                  <span>Stake Confirmation</span>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <p className="text-center">
                                  Are you sure you want to stake ?
                                </p>
                              </Modal.Body>
                              <Modal.Footer>
                                <div className="row">
                                  <div className="col-6">
                                    <Button
                                      variant="secondary"
                                      block
                                      onClick={() =>
                                        setShowConfirmationModal12(false)
                                      }
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                  <div className="col-6">
                                    <Button
                                      variant="success"
                                      block
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (balance) {
                                          N_setStake(
                                            user?.params
                                              ? user.params.token
                                              : user.token,
                                            round(onedtbxValueTwelveMonth),
                                            365
                                          ).then((d) => {
                                            if (d.status === 200) {
                                              N_getStake(
                                                user?.params
                                                  ? user.params.token
                                                  : user.token
                                              ).then((d) => {
                                                if (d.status === 200) {
                                                  setTwelveMonthData(d.result);
                                                }
                                              });
                                              NotificationManager.success(
                                                d.message
                                              );
                                              setTimeout(() => {
                                                window.location.reload();
                                              }, 2000);
                                            } else {
                                              NotificationManager.error(
                                                d.message
                                              );
                                            }
                                          });
                                        } else {
                                          NotificationManager.info(
                                            "Your Amount too Low"
                                          );
                                        }
                                      }}
                                    >
                                      Confirm
                                    </Button>
                                  </div>
                                </div>
                              </Modal.Footer>
                            </Modal>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {/* -------------------------------------------------------  */}
              </div>
            </div>
            {/* ----------------------------- */}
            {refUser && refUser.length > 0 ? (
              <div className="list-staking">
                <div
                  className="row ant-row-center"
                  style={{
                    rowGap: "30px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    className="col col-md-12 col-sm-12 banner-stake"
                    style={{ height: "100px", background: "#60a7ab" }}
                  >
                    <div className="banner-stake--container">
                      <div className="">
                        <div className="bg-shadow">
                          <h3>DTBX Referral Farm</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* --------------------------------------------------- */}
                  {/* referal user staking */}
                  {/* {refUser && refUser.length > 0
                    ? refUser.map((result, key) => {
                        return (
                          <div
                            className="col-lg-3 col-md-6 col-sm-12"
                            key={result.user_id}
                          >
                            <div className="item-staking">
                              <div className="header-item">
                                <div className="logo-token">
                                  <img src="/favicon.ico" alt="logo-token" />
                                </div>
                                <div className="info-pool textmode">
                                  <h2 className="textmode">DTBX FARM</h2>
                                </div>
                              </div>
                              <div className="content-item">
                                <div className="show-apr textmode">
                                  <div className="title-apr">User</div>
                                  <div className="apr">
                                    <span>
                                      {result.user_credential
                                        ? result.user_credential
                                        : 0}
                                    </span>
                                  </div>
                                </div>
                                <div class="staked-lp">
                                  <span class="blur-text textmode">
                                    Staked:{" "}
                                  </span>
                                  <span
                                    class="textmode staked-lp-amount"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {result.total_invested_dtbx
                                      ? round(result.total_invested_dtbx)
                                      : 0}
                                  </span>
                                </div>
                                <div class="staked-lp">
                                  <span class="blur-text textmode">
                                    Staking Days{" "}
                                  </span>
                                  <span
                                    class="textmode staked-lp-amount"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {result.invest_time
                                      ? result.invest_time
                                      : 0}{" "}
                                    days
                                  </span>
                                </div>
                                <div class="staked-lp">
                                  <span class="blur-text textmode">
                                    Staking Percentage{" "}
                                  </span>
                                  <span
                                    class="textmode staked-lp-amount"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {result.percent ? result.percent : 0} %
                                  </span>
                                </div>
                                <div className="wrap-amount-stake wrap-earn textmode">
                                  <div className="title-amount-stake textmode">
                                    DTBX
                                    <span className="badge">
                                      Interest EARNED
                                    </span>
                                  </div>
                                  <div className="token-earn">
                                    <h3 className="textmode">
                                      {result.total_ry
                                        ? round(result.total_ry)
                                        : 0}
                                    </h3>
                                  </div>
                                </div>
                                <div className="wrap-detail-and-harvest textmode">
                                  <div className="btn-show-detail">
                                    Withdraw Interest
                                  </div>
                                  <div className="harvest">
                                    <button
                                      disabled={
                                        result.total_ry > 0 ? "" : "disabled"
                                      }
                                      type="button"
                                      className="ant-btn"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        const token = user?.params
                                          ? user.params.token
                                          : user.token;
                                        const invest_time = result.invest_time; // days
                                        if (result.total_ry > 0) {
                                          N_harvestRefStaking(
                                            token,
                                            result.user_id,
                                            invest_time,
                                            round(result.total_ry)
                                          ).then((d) => {
                                            if (d.status === 200) {
                                              N_getRefStaking(token).then(
                                                (d) => {
                                                  if (d.status === 200) {
                                                    setRefUser(d.result);
                                                  }
                                                }
                                              );
                                              NotificationManager.success(
                                                d.message
                                              );
                                            } else {
                                              NotificationManager.error(
                                                d.message
                                              );
                                            }
                                          });
                                        } else {
                                          NotificationManager.info(
                                            "You Have not Earn"
                                          );
                                        }
                                      }}
                                    >
                                      <span>Withdraw</span>
                                    </button>
                                  </div>
                                </div>
                                <div class="wrap-amount-stake textmode">
                                  <div class="input-stake-withdraw">
                                    <div class="balance-lp-and-staked">
                                      {" "}
                                      <div class="balance-lp">
                                        <span class="blur-text textmode">
                                          Available {invest_type1}:{" "}
                                        </span>
                                        <span
                                          class="textmode balance-lp-amount"
                                          style={{ cursor: "pointer" }}
                                        >
                                          {balance}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : null} */}
                  <div className="mt-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mt-2 mb-2">
                          <h6 className="col-md-12 page-title">
                            Your Referral Users Staking Details
                          </h6>
                          <div className="d-flex justify-content-center">
                            <div className="text-center bg-light text-dark px-2 py-2 rounded-2">
                              <span className="blur-text textmode">
                                Aviable {invest_type1} Balance:{" "}
                              </span>
                              <span className="textmode balance-lp-amount">
                                {balance}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="overflow-auto mt-3">
                          <div className="table_scroll_div">
                            <table className="table global_table table-bordered">
                              <thead className="bg-light">
                                <tr>
                                  <th className="tdCenter">NO.</th>
                                  <th className="tdCenter">User</th>
                                  <th className="tdCenter">Staked</th>
                                  <th className="tdCenter">Staking Days</th>
                                  <th className="tdCenter">
                                    Staking Percentage
                                  </th>
                                  <th className="tdCenter">Interest Earned</th>
                                  <th className="tdCenter">Maturity Date</th>
                                  <th className="tdCenter">
                                    Withdraw Interest
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {refUser && refUser.length > 0
                                  ? refUser.map((result, key) => {
                                    return (
                                      <tr>
                                        <td className="tdCenter">
                                          {key + 1}
                                        </td>
                                        <td className="tdCenter">
                                          {result.user_credential
                                            ? result.user_credential
                                            : 0}
                                        </td>
                                        <td className="tdCenter">
                                          {result.total_invested_dtbx
                                            ? round(
                                              result.total_invested_dtbx
                                            )
                                            : 0}
                                        </td>
                                        <td className="tdCenter">
                                          {result.invest_time
                                            ? result.invest_time
                                            : 0}
                                        </td>
                                        <td className="tdCenter">
                                          {result.percent
                                            ? result.percent
                                            : 0}{" "}
                                          %
                                        </td>
                                        <td className="tdCenter">
                                          {" "}
                                          {result.total_ry
                                            ? round(result.total_ry)
                                            : 0}
                                        </td>
                                        <td className="tdCenter">
                                          {" "}
                                          {result.timeEx
                                            ? new Date(
                                              result.timeEx
                                            ).toLocaleDateString()
                                            : 0}
                                        </td>
                                        <td>
                                          <>
                                            <div className="harvest">
                                              <button
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  const token = user?.params
                                                    ? user.params.token
                                                    : user.token;
                                                  const invest_time =
                                                    result.invest_time; // days
                                                  if (result.total_ry > 0) {
                                                    N_harvestRefStaking(
                                                      token,
                                                      result.user_id,
                                                      invest_time,
                                                      round(result.total_ry)
                                                    ).then((d) => {
                                                      if (d.status === 200) {
                                                        N_getRefStaking(
                                                          token
                                                        ).then((d) => {
                                                          if (
                                                            d.status === 200
                                                          ) {
                                                            setRefUser(
                                                              d.result
                                                            );
                                                          }
                                                        });
                                                        NotificationManager.success(
                                                          d.message
                                                        );
                                                      } else {
                                                        NotificationManager.error(
                                                          d.message
                                                        );
                                                      }
                                                    });
                                                  } else {
                                                    NotificationManager.info(
                                                      "You Have not Earn"
                                                    );
                                                  }
                                                }}
                                                className="ant-btn"
                                              >
                                                <span>Withdraw</span>
                                              </button>
                                            </div>
                                          </>
                                        </td>
                                      </tr>
                                    );
                                  })
                                  : null}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Staking;
