import React, { useEffect, useState } from "react";
import Header from "./HomeComp/Header";
import ProfileSidebar from "./components/ProfileSidebar";
import { BiError, BiMobileAlt, BiSolidCheckShield } from "react-icons/bi";
import {
  AiFillInfoCircle,
  AiOutlineCamera,
  AiOutlineCloudUpload,
} from "react-icons/ai";
import {
  N_addBinficiary,
  N_addUPI,
  N_checkKYCStatus,
  N_getBank,
  N_getCountry,
  N_getUserBankDetails,
  N_getUserUPIDetails,
  N_profileData,
  N_uploadBankDoc,
  N_uploadID,
} from "./redux/helpers/api_functions_new";
import { useSelector } from "react-redux";
import $ from "jquery";
import Loader from "./components/Loader";
import { FaCheckCircle } from "react-icons/fa";
import { checkUPI } from "./redux/helpers/helper_functions";
import {
  isAccount,
  isAccounttype,
  isBankifsc,
  isBanktype,
  isUPIID,
} from "./redux/helpers/form-validator.functions";
// import Footer from "./HomeComp/Footer";
export default function MyBanks(props) {
  const { user, profile } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = useState(true);
  const [isverified, setIsverified] = useState(0);
  const [filedata, setFileData] = useState();

  const [accountNo, setAccountNo] = useState("");
  const [UPI_ID, setUPI_ID] = useState("");
  const [reaccountNo, setReAccountNo] = useState();
  const [account_name, setAccountName] = useState();
  const [selectedBank, setSelectedBank] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [bankIFCS, setIFSC] = useState("");
  const [banklist, setBank] = useState();
  const [accountType, setAccountType] = useState("");
  const [bankStatus, setBankStatus] = useState(-1);
  const [bankDetails, setBankDetails] = useState([]);
  const [upiDetails, setUPIDetails] = useState([]);
  const [errorMessageupi, setErrorMessageupi] = useState([]);

  const [isFileUploaded, setFileUploaded] = useState(false);

  const [showBankForm, setShowBankForm] = useState(1); // New state variable
  const [active, setActive] = useState(0);
  const handleAddBankClick = () => {
    setShowBankForm(1);
    closeDropdown();
  };
  const handleAddUpiClick = () => {
    setShowBankForm(2);
    closeDropdown();
  };

  const handleShowAllBanks = () => {
    setShowBankForm(0);
  };

  const editBankDetails = (e) => {
    e.preventDefault();
    const token = user?.params ? user.params.token : user.token;
    N_getUserBankDetails(token).then((d) => {
      if (d.status === 200) {
        const { account_number, account_type, bank_name, doc, name, ifsc } =
          d.params.bank_details;
        setAccountNo(account_number);
        setReAccountNo(account_number);
        setAccountName(name);
        setSelectedBank(bank_name);
        setIFSC(ifsc);
      }
    });
  };

  useEffect(() => {
    N_getBank(user?.params ? user.params.token : user.token)
      .then((d) => {
        if (d.status === 200) {
          setBank(d.params.bank);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, [user]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (showBankForm == 2) {
      if (isUPIID(UPI_ID))
        if (checkUPI(UPI_ID)) {
          setLoading(true);
          N_addUPI(UPI_ID, user?.params ? user.params.token : user.token).then(
            (res) => {
              setErrorMessageupi(res.message);
              N_getUserUPIDetails(
                user?.params ? user.params.token : user.token
              ).then((data) => {
                if (data.status === 200) {
                  if (data.params.status) setShowBankForm(0);
                  setUPIDetails(data.params.bank_details);
                  setActive(1);
                  setUPI_ID("");
                  setLoading(false);
                }
              });
            }
          );
        } else {
          setErrorMessageupi(
            "Enter a valid UPI ID in the format username@domain.com"
          );
        }
    } else {
      if (
        isAccount(accountNo) &&
        isAccounttype(accountType) &&
        isBanktype(selectedBank) &&
        isBankifsc(bankIFCS)
      ) {
        if (
          accountNo == "" &&
          bankIFCS == "" &&
          accountType == "" &&
          selectedBank == ""
        ) {
          setErrorMessage(
            accountNo == ""
              ? "Please Fill Account Number"
              : accountType == ""
              ? "Please Select account type"
              : selectedBank == ""
              ? "Please Select bank type"
              : "Please Fill IFSC Code"
          );
        } else {
          // console.log("OK");
          setLoading(true);
          N_addBinficiary(
            accountNo,
            // reaccountNo,
            selectedBank,
            // account_name,
            bankIFCS,
            accountType,
            user?.params ? user.params.token : user.token
          ).then((d) => {
            if (d.status == 200) {
              setErrorMessage(d.message);
              N_getUserBankDetails(
                user?.params ? user.params.token : user.token
              ).then((data) => {
                if (data.status === 200) {
                  if (data.params.status) setShowBankForm(0);
                  setBankDetails(data.params.bank_details);
                  setActive(0);
                }
              });
            } else {
              setErrorMessage(d.message);
            }

            setLoading(false);
          });
        }
      }
    }
  };

  useEffect(() => {
    N_getUserBankDetails(user?.params ? user.params.token : user.token)
      .then((data) => {
        if (data.status === 200) {
          setBankStatus(data.params.status);
          setBankDetails(data.params.bank_details);
          if (data.params.bank_details.length > 0) {
            setShowBankForm(0);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });

    N_getUserUPIDetails(user?.params ? user.params.token : user.token)
      .then((data) => {
        if (data.status === 200) {
          setBankStatus(data.params.status);
          setUPIDetails(data.params.bank_details);
          if (data.params.bank_details.length > 0) {
            setShowBankForm(0);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  function readURL(input) {
    const file = input.target.files[0];
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      setFileData(file);
      setFileUploaded(true);
      reader.onload = function (e) {
        $("#check_photo").attr("src", e.target.result);
      };
    }
  }

  const closeDropdown = () => {
    // Get the dropdown button element
    const dropdownButton = document.getElementById("dwn");
    // Close the dropdown by removing the 'show' class from the button
    dropdownButton.classList.remove("show");
  };

  return (
    <>
      <Header {...props} />
      <div className="s-layout">
        <div class="s-layout__sidebar">
          <ProfileSidebar />
        </div>
        {loading ? (
          <Loader />
        ) : (
          <main className="s-layout__content">
            <div className="dashboard_header">
              <h3 className="header_title">Bank Accounts</h3>
              <div class="flex_row gap-2 mb-2">
                <div className="dropdown">
                  <button
                    className={`btn dropdown-toggle ${
                      showBankForm !== 0
                        ? " btn-primary"
                        : " btn-outline-primary"
                    } rounded-pill`}
                    type="button"
                    id="accountsmenu"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Add Account
                  </button>

                  <ul
                    class="shadow dropdown-menu border-0"
                    id="dwn"
                    aria-labelledby="accountsmenu"
                  >
                    <li>
                      <a
                        class="dropdown-item text-lgray"
                        onClick={handleAddUpiClick}
                      >
                        UPI
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item text-lgray"
                        onClick={handleAddBankClick}
                      >
                        Bank
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <button
                    className={`btn${
                      showBankForm == 0
                        ? " btn-primary"
                        : " btn-outline-primary"
                    } rounded-pill`}
                    onClick={handleShowAllBanks}
                  >
                    All Accounts
                  </button>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="account_page">
                {showBankForm == 0 &&
                bankDetails.length == 0 &&
                upiDetails.length == 0 ? (
                  <div className="text-center">
                    <img src="/img/no-data.png" className="no-data-found" />
                    <p className="text-lgray fs-12">
                      There are no bank details or UPI accounts added.
                    </p>
                  </div>
                ) : null}

                <div class="row">
                  <div class="col-lg-8">
                    <div className="col-lg-12 mb-5">
                      {/* UPI form field to fill */}
                      {showBankForm == 2 && (
                        <div className="card-body">
                          <div className="">
                            <h4>Add UPI</h4>
                            <p className="text-lgray fs-14">
                              Provide information for the process of adding an
                              UPI
                            </p>
                            <div className="text-danger mb-0 fs-14 error_div">
                              {" "}
                              {setErrorMessageupi}
                            </div>
                            <form
                              className="mdfthemetxt"
                              method="post"
                              id="loginForm"
                              autoComplete="off"
                              onSubmit={onSubmit}
                            >
                              <div className="row mt-2">
                                <div className="col-lg-6">
                                  <div className="form-group mb-3">
                                    <label
                                      for="exampleInputEmail1"
                                      className="text-muted"
                                    >
                                      UPI ID
                                    </label>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className="form-control colr text-dark"
                                        placeholder="Enter UPI ID"
                                        id="bank_upi"
                                        name="bank_upi"
                                        required=""
                                        value={UPI_ID}
                                        onChange={(e) => {
                                          setUPI_ID(e.target.value);
                                        }}
                                      />
                                      <span className="input-group-text">
                                        <button
                                          type="submit"
                                          className="btn border-0"
                                        >
                                          {loading ? (
                                            <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                                          ) : null}
                                          <span id="reg">Add UPI</span>
                                        </button>
                                      </span>
                                    </div>
                                    <small
                                      className="text-danger"
                                      id="passerr2"
                                    ></small>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}

                      {showBankForm == 0 &&
                      (bankDetails.length > 0 || upiDetails.length > 0) ? (
                        <div className="mt-3">
                          <nav className="justify-content-start d-flex gap-2 custom_pills ">
                            <div
                              class="nav nav-pills mb-3"
                              id="nav-tab"
                              role="tablist"
                            >
                              <button
                                class={`nav-link${
                                  active == 0 ? " active" : ""
                                }`}
                                id="nav-bank-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-bank"
                                type="button"
                                role="tab"
                                aria-controls="nav-bank"
                                aria-selected="true"
                                onClick={() => setActive(0)}
                              >
                                Bank Accounts
                              </button>
                              <button
                                class={`nav-link${
                                  active == 1 ? " active" : ""
                                }`}
                                id="nav-upi-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-upi"
                                type="button"
                                role="tab"
                                aria-controls="nav-upi"
                                aria-selected="false"
                                onClick={() => setActive(1)}
                              >
                                UPI
                              </button>
                            </div>
                          </nav>

                          <div class="tab-content" id="nav-tabContent">
                            <div
                              class={`tab-pane fade${
                                active == 0 ? " active" : ""
                              } show`}
                              id="nav-bank"
                            >
                              <div className="jambotron mb-3">
                                <div className="text-danger mb-0 fs-14 error_div">
                                  {" "}
                                  {errorMessage}
                                </div>
                                <div className="card-body">
                                  {bankDetails.length > 0 ? (
                                    bankDetails.map((item) => {
                                      return (
                                        <div className="bank-details">
                                          <div className="flex_row mb-2">
                                            <div className="text-lgray">
                                              Bank Name
                                            </div>

                                            <div className="fw-bold">
                                              {item.bank_name}
                                              <FaCheckCircle className="text-green ms-2" />
                                            </div>
                                          </div>

                                          <div className="flex_row mb-2">
                                            <div className="text-lgray">
                                              Account Name
                                            </div>

                                            <div className=""> {item.name}</div>
                                          </div>
                                          <div className="flex_row mb-2">
                                            <div className="text-lgray">
                                              Account Number
                                            </div>

                                            <div className="">
                                              {item.account_number}
                                            </div>
                                          </div>

                                          <div className="flex_row mb-2">
                                            <div className="text-lgray">
                                              IFSC Code
                                            </div>

                                            <div className="">{item.ifsc}</div>
                                          </div>

                                          <div className="flex_row mb-2">
                                            <div className="text-lgray">
                                              Account Type
                                            </div>

                                            <div className="">
                                              {item.account_type}
                                            </div>
                                          </div>
                                          <div className="flex_row mb-2">
                                            <div className="text-lgray">
                                              Status
                                            </div>

                                            <div className="">
                                              {item.status == 1
                                                ? "Verified"
                                                : item.status == -1
                                                ? "Pending"
                                                : "Reject"}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div className="text-center">
                                      <img
                                        src="/img/no-data.png"
                                        className="no-data-found"
                                      />
                                      <p className="text-lgray fs-12">
                                        There are no bank details or UPI
                                        accounts added.
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div
                              class={`tab-pane fade${
                                active == 1 ? " active" : ""
                              } show`}
                              id="nav-upi"
                            >
                              <div className="jambotron mb-3">
                                <div className="card-body">
                                  {upiDetails.length > 0 ? (
                                    upiDetails.map((item) => {
                                      return (
                                        <div className="bank-details">
                                          <div className="flex_row">
                                            <div className="text-lgray">
                                              UPI ID
                                            </div>

                                            <div className="">
                                              {item.upi_id}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div className="text-center">
                                      <img
                                        src="/img/no-data.png"
                                        className="no-data-found"
                                      />
                                      <p className="text-lgray fs-12">
                                        There are no bank details or UPI
                                        accounts added.
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {/* {loading ? <Loader /> : null} */}
                      {showBankForm == 1 ? (
                        // {!loading && ( bankStatus == 0 || bankStatus == 2) ? (
                        <>
                          <div className="card-body">
                            <h4>Add Bank Details</h4>
                            <p className="text-lgray fs-14">
                              Provide information for the process of adding a
                              bank
                            </p>

                            <div className="text-danger mb-0 fs-14 error_div">
                              {" "}
                              {errorMessage}
                            </div>
                            <form
                              className="mdfthemetxt"
                              method="post"
                              id="loginForm"
                              autoComplete="off"
                              onSubmit={onSubmit}
                            >
                              <div className="row mt-2">
                                <div className="col-lg-6">
                                  <div className="form-group mb-3">
                                    <label
                                      for="exampleInputEmail1"
                                      className="text-muted"
                                    >
                                      Account Number
                                    </label>
                                    <input
                                      type="text"
                                      id="account_no"
                                      nmae="account_no"
                                      className="form-control colr text-dark"
                                      placeholder="Enter Account no"
                                      value={accountNo}
                                      onChange={(e) =>
                                        setAccountNo(
                                          isAccount(
                                            e.target.value
                                              .replace(/[^0-9.]/g, "")
                                              .replace(/(\..*?)\..*/g, "$1")
                                          )
                                        )
                                      }
                                    />
                                    <small>Error message</small>
                                  </div>

                                  <div className="form-group mb-3">
                                    <label
                                      for="exampleInputEmail1"
                                      className="text-muted"
                                    >
                                      Bank Name
                                    </label>
                                    <select
                                      className="form-control buy-sell-form-bg buy-sell-theme"
                                      name="bank_type"
                                      id="bank_type"
                                      onChange={(e) =>
                                        setSelectedBank(
                                          isBanktype(e.target.value)
                                        )
                                      }
                                    >
                                      <option selected>Please Select</option>
                                      {banklist?.map((d) => (
                                        <option className="">
                                          {d.bank_name}
                                        </option>
                                      ))}
                                    </select>
                                    <small>Error message</small>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group mb-3">
                                    <label
                                      for="account type"
                                      className="text-muted"
                                    >
                                      Account Type
                                    </label>
                                    <select
                                      className="form-control buy-sell-form-bg buy-sell-theme"
                                      name="account_type"
                                      id="account_type"
                                      onChange={(e) =>
                                        setAccountType(
                                          isAccounttype(e.target.value)
                                        )
                                      }
                                    >
                                      <option selected className="">
                                        Please Select
                                      </option>
                                      <option>Current</option>
                                      <option>Saving</option>
                                    </select>

                                    <small>Error message</small>
                                  </div>

                                  <div className="form-group">
                                    <label
                                      for="ifsc code"
                                      className="text-muted"
                                    >
                                      Bank IFSC
                                    </label>
                                    <input
                                      type="text"
                                      name="ifsc_code"
                                      id="ifsc_code"
                                      className="form-control colr text-dark"
                                      placeholder="Enter Bank IFSC"
                                      value={bankIFCS}
                                      onChange={(e) =>
                                        setIFSC(
                                          isBankifsc(
                                            e.target.value.toUpperCase()
                                          )
                                        )
                                      }
                                    />
                                    <small>Error message</small>
                                  </div>
                                </div>

                                <div className="text-end my-2">
                                  <button
                                    type="submit"
                                    className="btn btn-primary px-4"
                                  >
                                    {loading ? (
                                      <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                                    ) : null}
                                    <span id="reg">Add Bank</span>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        )}
      </div>
    </>
  );
}
