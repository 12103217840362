import React, { useState, useEffect } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Header from "./HomeComp/Header";
import "./sidebar.css";
import { BiSolidCheckShield } from "react-icons/bi";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  hexToRgb,
} from "@mui/material";
import { FaCheckCircle, FaInfoCircle } from "react-icons/fa";
import {
  AiFillSafetyCertificate,
  AiOutlineQuestionCircle,
  AiOutlineSafety,
} from "react-icons/ai";
import { CiCircleQuestion, CiSearch, CiSignpostDuo1 } from "react-icons/ci";
import { FcInfo } from "react-icons/fc";
import { BsShieldExclamation, BsSignpost, BsSignpost2 } from "react-icons/bs";
import { IoReloadSharp } from "react-icons/io5";
import P2PBuyNSellNew from "./components/p2pBuyNSellNew";
import { PiSignpostDuotone } from "react-icons/pi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import {
  N_createp2pSellOrder,
  N_get_walletp2p,
  N_p2pSellOrder,
  N_paymentMethod,
} from "./redux/helpers/api_functions_new";
import { FiSmartphone } from "react-icons/fi";

export default function OrderlistP2p(props) {
  const { user } = useSelector((state) => state.AuthReducer);
  const { coins } = useSelector((state) => state.coinDBReducer);
  const [wallets, setWallets] = useState([]);
  const [buyorder, setBuyOrder] = useState([]);
  const [sellorder, setSellOrder] = useState([]);
  const [data, setdata] = useState(null);
  const [amountinr, setAmonutINR] = useState(null);
  const [amountusdt, setAmountUSDT] = useState(null);
  const [buyamountinr, setBuyAmountINR] = useState(null);
  const [buyamountusdt, setBuyAmountUsdt] = useState(null);
  const [sellamountinr, setSellAmountINR] = useState(null);
  const [sellamountusdt, setSellAmountUsdt] = useState(null);
  const [price, setPrice] = useState(null);
  const [payment_method, setPaymentMenthod] = useState(null);
  const [payment_method_data, setPaymentMenthodData] = useState([]);
  const [buyData, setBuyData] = useState(null);
  const [sellData, setSellData] = useState(null);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  // for tab
  const [activeTab, setActiveTab] = useState("buy");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [activeTabBuySell, setActiveTabBuySell] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTabBuySell(tabNumber);
  };

  // for filter option
  const [selectedOption, setSelectedOption] = useState("USDT");
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [selectedOption2, setSelectedOption2] = useState("option2");

  const handleChange2 = (event) => {
    setSelectedOption2(event.target.value);
  };

  const [selectedOptionrefresh, setSelectedOptionrefresh] = useState("option3");

  const handleRefresh = (event) => {
    setSelectedOptionrefresh(event.target.value);
  };

  //for bank payment
  const [selectedOptionBank, setSelectedOptionBank] = useState("option1");

  const handleBank = (event) => {
    setSelectedOptionBank(event.target.value);
  };

  // for multiple option selection dropdown

  const [selectedMultioptions, setSelectedMultioptions] = useState(["option1"]);
  const options = [
    { value: "option1", label: "All Payment Method" },
    { value: "option2", label: "UPI" },
    { value: "option3", label: "Google Pay" },
    { value: "option4", label: "PhonePe" },
    { value: "option5", label: "Bank Transfer" },
    { value: "option6", label: "PayTm" },
    { value: "option7", label: "Airtel Money" },
    { value: "option8", label: "State Bank" },
    { value: "option9", label: "PNB Bank" },
  ];

  const handleMultiOptions = (event) => {
    setSelectedMultioptions(event.target.value);
  };

  const handleChipDelete = (chipToDelete) => () => {
    setSelectedMultioptions((chips) =>
      chips.filter((chip) => chip !== chipToDelete)
    );
  };

  //another multioption

  const [selectedBankoptions, setSelectedBankoptions] = useState([]); // Store selected options in an array
  const payoptions = [
    { value: "option1", label: "Not now" },
    { value: "option2", label: "5s to refresh" },
    { value: "option3", label: "10s to refresh" },
    { value: "option4", label: "20s to refresh" },
  ];

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedBankoptions((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((option) => option !== value)
        : [...prevSelected, value]
    );
  };

  //for popover
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // for tooltip
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
      "&::before": {
        backgroundColor: theme.palette.common.black,
        border: "1px solid #f5f5f5",
      },
    },

    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#000",

      maxWidth: 200,
    },
  }));

  useEffect(() => {
    N_get_walletp2p(user?.params ? user.params.token : user.token).then(
      (res) => {
        if (res.status == 200) {
          setWallets(Object.values(res.params.wallets));
        }
      }
    );
  }, []);

  useEffect(() => {
    N_p2pSellOrder(
      user?.params ? user.params.token : user.token,
      activeTab,
      selectedOption
    ).then((res) => {
      if (res.status == 200) {
        if (activeTab == "buy") setBuyOrder(res.p2porder);
        if (activeTab == "sell") setSellOrder(res.p2porder);
      }
    });
  }, [activeTab, selectedOption]);

  useEffect(() => {
    if (!selectedOption || !coins || !wallets) return;

    const coinData = Object.values(coins).find(
      (item) => item.symbol === selectedOption
    );
    const walletData = wallets.find((item) => item.symbol === selectedOption);

    if (coinData && walletData) {
      const combinedData = {
        ...walletData,
        current_price: coinData.current_price,
        spread_inr: coinData.spread_inr,
        spread_usdt: coinData.spread_usdt,
      };
      setdata(combinedData);
      setPrice(
        parseFloat(combinedData.current_price.toFixed(combinedData.spread_inr))
      );
    }
  }, [coins, wallets, selectedOption]);

  useEffect(() => {
    N_paymentMethod(user?.params ? user.params.token : user.token).then(
      (res) => {
        if (res.status == 200) {
          setPaymentMenthodData(res.data);
        }
      }
    );
  }, []);

  let p2pcoin =
    wallets.length > 0 &&
    wallets.map((item, index) => {
      return (
        <MenuItem key={index} className="select-option" value={item.symbol}>
          <img
            src={item.icon}
            alt={`option ${index}`}
            style={{
              marginRight: "8px",
              width: "14px",
              height: "14px",
            }}
          />
          {item.symbol}
        </MenuItem>
      );
    });

  return (
    <>
      <Header />
      <div className="page-content">
        <div class="tab_area">
          <div class="container custom_pills">
            <div className="row">
              <div className="col-lg-9">
                <div class="nav nav-pills">
                  <a class="nav-item nav-link " href="/FastTrade">
                    Fast Trade
                  </a>
                  <a class="nav-item nav-link active" href="/p2pOrder">
                    P2P
                  </a>
                  {/* <a class="nav-item nav-link" href="/ThirdpartyOrder">
                Third Party
              </a> */}
                </div>
              </div>
              <div className="col-lg-3 text-end">
                <div class="dropdown">
                  <button
                    class="btn border-0 dropdown-toggle"
                    type="button"
                    id="accountsmenu"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Ads
                  </button>
                  <ul
                    class="shadow dropdown-menu border-0"
                    id="dwn"
                    aria-labelledby="accountsmenu"
                  >
                    <li>
                      <a
                        data-bs-target="#ads_post_modal"
                        data-bs-toggle="modal"
                        class="dropdown-item cursor_pointer"
                      >
                        Post Ads
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item cursor_pointer">My Ads</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main className="container mt-5">
          <div className="row">
            <div className="col-lg-12 mb-3 ">
              <h5 className="mb-3 d-none d-lg-block">Buy USDT</h5>

              <div className="row">
                <div className="col-lg-8">
                  <div className="jambotron-outline ">
                    <h5>Complete Your Payment Within:</h5>
                    <ul class="fs-12">
                      <li>
                        Please complete payment within the allowed timeframe.
                      </li>
                      <li>
                        After making the payment using an payment method outside
                        of Bybit, please click on the "I have completed the
                        payment" button below.
                      </li>
                      <li>
                        Note: The order will be automatically canceled if the
                        button is not clicked by the deadline.
                      </li>
                    </ul>

                    <div class="order-detail-step mt-4">
                      <div class="d-flex gap-2 align-items-center active">
                        <div class="ant-space-item">
                          <div class="step-value">1</div>
                        </div>
                        <div class="ant-space-item">
                          <div class="step-label">Complete Your Payment</div>
                        </div>
                        <div class="ant-space-item">
                          <div class="step-dash-line"></div>
                        </div>
                      </div>
                      <div class="d-flex gap-2 align-items-center inactive">
                        <div class="ant-space-item">
                          <div class="step-value">2</div>
                        </div>
                        <div class="ant-space-item">
                          <div class="step-label">Coin Release in Progress</div>
                        </div>
                        <div class="ant-space-item">
                          <div class="step-dash-line"></div>
                        </div>
                      </div>
                      <div class="d-flex gap-2 align-items-center inactive">
                        <div class="ant-space-item">
                          <div class="step-value">3</div>
                        </div>
                        <div class="ant-space-item">
                          <div class="step-label">Transaction Completed</div>
                        </div>
                      </div>
                    </div>

                    <h5 className="hero_heading mt-4">Order Info</h5>
                    <div class="order-detail-step justify-content-between mt-2">
                      <div className="col-lg-4">
                        <h4 className="h6 text-lgray">Pay</h4>
                        <div className="text-green">1,00,000 INR</div>
                      </div>
                      <div className="col-lg-4">
                        <h4 className="h6 text-lgray">Price</h4>
                        <div>96.41 INR</div>
                      </div>
                      <div className="col-lg-4">
                        <h4 className="h6 text-lgray">Total Quanity</h4>
                        <div>10.2356 USDT</div>
                      </div>
                    </div>

                    <h5 className="hero_heading mt-4">
                      Payment Methods Accepted by the Seller
                    </h5>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Paytm
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="paymentMethod"
                        id="googlePay"
                        checked={showAdditionalInfo} // Make sure this is checked when additional info is shown
                        onChange={() =>
                          setShowAdditionalInfo(!showAdditionalInfo)
                        }
                      />
                      <label className="form-check-label" htmlFor="googlePay">
                        Google Pay
                      </label>

                      {showAdditionalInfo && (
                        <div className="col-lg-6">
                          <div className="d-flex justify-content-between my-2">
                            <div className="flex-grow-1 text-lgray">Name</div>
                            <div>Aman Kumar Pandey</div>
                          </div>
                          <div className="d-flex justify-content-between my-2">
                          <div className="flex-grow-1 text-lgray">UPI ID</div>
                          <div>Aman@namk.com</div>
                          </div>
                          <div className="d-flex justify-content-between my-2">
                          <div className="flex-grow-1 text-lgray">QR Code</div>
                            <div>
                              <img src="/img/qrcode.png" alt="QR Code" className="img-fluid" width={'150'} />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="jambotron-outline ">
                    <h2 class="para_heading mb-0"> FAQs </h2>
                    <div className="col-lg-12 mt-2">
                      <div
                        class="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="flush-headingOne">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOne"
                              aria-expanded="false"
                              aria-controls="flush-collapseOne"
                            >
                              Why is KYC important?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseOne"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              <ul className="list-unstyled faq_kyc_bullets">
                                <li>
                                  <strong>1. Compliance:</strong> Financial
                                  institutions and trading platforms are
                                  required to comply with regulatory standards
                                  set by government authorities. KYC regulations
                                  are often mandated by laws such as the USA
                                  PATRIOT Act in the United States or the
                                  Anti-Money Laundering Directive in the
                                  European Union.
                                </li>

                                <li>
                                  <strong>2. Risk Management:</strong> KYC
                                  processes help in assessing and managing risks
                                  associated with financial transactions. By
                                  verifying the identities of customers,
                                  businesses can better understand their
                                  clients' risk profiles and detect suspicious
                                  activities.
                                </li>

                                <li>
                                  <strong>3. Fraud Prevention:</strong>Verifying
                                  the identity of users helps prevent identity
                                  theft, account takeover, and other fraudulent
                                  activities. It adds an additional layer of
                                  security by ensuring that funds and assets are
                                  not being used unlawfully.
                                </li>

                                <li>
                                  <strong>4. Global Standards:</strong> Many
                                  international financial institutions and
                                  trading platforms operate across borders.
                                  Standardizing KYC procedures helps ensure
                                  consistency and compliance with global
                                  anti-money laundering (AML) and
                                  counter-terrorism financing (CTF) standards.
                                </li>

                                <li>
                                  <strong>5. Customer Protection: </strong> KYC
                                  verification also benefits customers by
                                  protecting their accounts and assets from
                                  unauthorized access and fraud. It contributes
                                  to building trust between the trading platform
                                  and its users.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="flush-headingTwo">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseTwo"
                              aria-expanded="false"
                              aria-controls="flush-collapseTwo"
                            >
                              How long does the KYC verification process take?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseTwo"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingTwo"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              The duration of the KYC verification process can
                              vary depending on several factors, including the
                              specific financial institution or trading
                              platform, the volume of applications being
                              processed, the completeness of the submitted
                              documents, and the efficiency of the verification
                              procedures.
                              <br /> <br />
                              Typically, the KYC verification process is
                              completed within about<strong> 15 minutes</strong>
                              . However, in certain cases where more detailed
                              information needs to be verified, the process may
                              take up to 48 hours.
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="flush-headingThree">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseThree"
                              aria-expanded="false"
                              aria-controls="flush-collapseThree"
                            >
                              Can I amend my KYC's information (Proof of
                              Identity & Proof of Address) after verification?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseThree"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              No, you can't amend your KYC information after
                              verification unless your legal information has
                              changed, i.e. Legal Name, Nationality.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div class="jambotron mb-4">
                    <div class="d-flex justify-content-between align-items-center gap-3">
                      <div>
                        <h5 className="fs-14">Download App</h5>
                        <div class="text-lgray">
                          Get FRX App for the next level trading experience!
                        </div>
                      </div>
                      <div>
                        <img
                          src="/img/app-download.png"
                          width="100px"
                          alt="app download"
                        />
                      </div>
                    </div>
                    <div class="d-grid mt-3">
                      <Link class="btn btn-outline-secondary" to="/">
                        <FiSmartphone className="me-2" />
                        Get FRX App
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
