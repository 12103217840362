import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import OrderSidebar from "./components/OrderSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdOutlineInfo } from "react-icons/md";
import {
  N_cancelFutureOrderById,
  N_futureOrder,
} from "./redux/helpers/api_functions_new";
import { getFutureUserBalance, getFutureUserOrder } from "./redux/actions/coinDBAction";
import { Helmet } from "react-helmet";
import { round } from "./redux/helpers/Math";
import { FiDelete } from "react-icons/fi";

export default function FutureOrders(props) {
  const dispatch = useDispatch();
  const { coins, paired_curency } = useSelector((state) => state.coinDBReducer);
  const { user } = useSelector((state) => state.AuthReducer);
  const [pair_data, setPairData] = useState();
  const [pair_currency, setPairCurrency] = useState("all");
  const [side, setSide] = useState("all");
  const [type, setType] = useState("all");
  const [action, setAction] = useState("open");
  const [openorder, setOpenOrder] = useState([]);
  const [total, setTotal] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); 


const handleNextPage = () => {
  setCurrentPage((prevPage) => prevPage + 1);
};

const handlePreviousPage = () => {
  setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
};


  useEffect(() => {
    if (coins && paired_curency.length > 0) {
      updatePair();
    }
  }, [coins, paired_curency]);
  function updatePair() {
    let paircurrency = [];
    paired_curency &&
      paired_curency.map((item) => {
        Object.values(coins).map((data) => {
          paircurrency.push(data.symbol + "/" + item.currency_coin);
        });
      });
    setPairData(paircurrency);
  }
  const pair =
    pair_data &&
    pair_data.map((item) => {
      return <option value={item}>{item}</option>;
    });
  useEffect(() => {
      N_futureOrder(
        user?.params?.token ? user.params.token : user.token,
        pair_currency,
        side,
        type,
        action,
        currentPage, itemsPerPage
      ).then((res) => {
        if (res.status == 200) {
          setOpenOrder(res.openorders);
          setTotal(res.totalOrders);
        }
      });
  }, [pair_currency, side, type, action, currentPage, itemsPerPage]);

  const downloadCSV = () => {
    const csvRows = [];
    
    // Headers
    csvRows.push([
      "Future Pairs", "Trade Type", "Order Type", "Direction", "Filled Value", 
      "Filled Price", "Filled Qty", "Filled Executed", "Trading Fee", "TDS Fee", "Filled Time", "Action"
    ].join(','));

    // Data Rows
    openorder.forEach(item => {
      if (
        item.order_status == 1 || item?.order_status == 3 ||
        (item.order_status == 2 && Number(item.total_executed) > 0)
      ) {
        csvRows.push([
          `${item?.currency_type.toUpperCase()}/${item?.compare_currency.toUpperCase()}`,
          "Future",
          item?.order_type.toUpperCase(),
          side,
          round(parseFloat(item?.volume) * item?.raw_price),
          round(item?.raw_price),
          round(item?.volume),
          round(item?.total_executed),
          round(item?.fees),
          round(item?.leverage),
          new Date(Number(item?.order_date)).toLocaleString(),
          item?.order_status === 3
            ? "Expired"
            : Number(item?.volume) === Number(item?.total_executed)
            ? "Completed"
            : "Cancel"
        ].join(','));
      }
    });

    // Create a Blob and trigger download
    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'future_order_history.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  };
  return (
    <>
      <div className="application">
        <Helmet>
          <meta
            name="description"
            content="Execute & track your spot orders with precision on FRX Exchange and
enjoy seamless trading experiences"
          />
          <title>Future Orders: FRX Blockchain </title>
        </Helmet>
      </div>

      <Header />
      <div className="s-layout">
        <div class="s-layout__sidebar">
          <OrderSidebar />
        </div>

        <main className="s-layout__content">
          <div className="dashboard_header">
            <h3 className="header_title"> Future </h3>
          </div>
          <div className="marginTop">
            <div className="account_page">
              <div className="container">
                <div className="row">
                  <div className="activity_panel mb-4">
                    <div className="">
                      <div class="custom_pills">
                        <div class="nav nav-pills" id="nav-tab" role="tablist">
                          <div
                            class="nav-item nav-link active fs-14"
                            style={{ height: "35px" }}
                            href="#open_order"
                            data-toggle="tab"
                            onClick={() => {
                              setAction("open");
                            }}
                          >
                            Open Orders
                          </div>
                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#order_history"
                            data-toggle="tab"
                            onClick={() => {
                              setAction("order");
                            }}
                          >
                            Order History
                          </div>

                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#trade_history"
                            data-toggle="tab"
                            onClick={(e) => {
                              setAction("position");
                            }}
                          >
                            Position History
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-content">
                  <div className="tab-pane fade active show" id="open_order">
                    <div className="row cstm_form mb-4">
                      <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e) => {
                            setPairCurrency(e.target.value);
                          }}
                        >
                          <option value="all" selected>
                            All
                          </option>
                          {pair}
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e) => {
                            setSide(e.target.value);
                          }}
                        >
                          <option value="all" selected>
                            All
                          </option>
                          <option value="sell">Sell</option>
                          <option value="buy">Buy</option>
                        </select>
                      </div>
                      {/* <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e) => {
                            setType(e.target.value);
                          }}
                        >
                          <option value="all" selected>
                            All{" "}
                          </option>
                          <option value="limit">Limit Order</option>
                          <option value="market">Market Order</option>
                        </select>
                      </div> */}
                    </div>

                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Future Pairs</th>
                              <th>Trade Type</th>
                              <th>Order Type</th>
                              <th>Direction</th>
                              <th>Filled Value</th>
                              <th>Filled Price</th>
                              <th>Filled Qty</th>
                              <th>Filled Executed</th>
                              <th>Fee</th>
                              <th>Leverage</th>
                              <th>Filled Time</th>
                              <th>Delete</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}
                            {openorder && openorder.length > 0 ? (
                              openorder.map((item) => {
                                return (
                                  <tr>
                                    <td className="border-bottom-0 text-muted">
                                    <p>
                                        {item?.currency_type.toUpperCase() +
                                          "/" +
                                          item?.compare_currency.toUpperCase()}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                        Future
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{item?.order_type.toUpperCase()}</p>
                                    </td>
                                    <td className={`border-bottom-0 text-muted${side === "buy" ? " text-green" :side === "sell"?" text-red":""}`}>
                                      <p>{side}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(parseFloat(item?.volume) * item?.raw_price)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(item?.raw_price)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(item?.volume)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(item?.total_executed)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                      {round(item?.fees?item?.fees:0)}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                      {round(item?.leverage)}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                      {new Date(Number(item?.order_date)).toLocaleString()}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted text-center">
                                      <p>
                                        <div
                                          onClick={(e) => {
                                            e.preventDefault();
                                            N_cancelFutureOrderById(
                                              user?.params?.user_id
                                                ? user.params.token
                                                : user.token,
                                              item?.order_id
                                            )
                                              .then((res) => {
                                                if (res.status === 200) {
                                                  dispatch(
                                                    getFutureUserOrder(
                                                      user?.params?.token
                                                        ? user.params.token
                                                        : user.token

                                                    )
                                                  );
                                                  dispatch(
                                                    getFutureUserBalance(
                                                      user?.params?.token
                                                        ? user.params.token
                                                        : user.token
                                                    )
                                                  );
                                                  N_futureOrder(
                                                    user?.params?.token
                                                      ? user.params.token
                                                      : user.token,
                                                    pair_currency,
                                                    side,
                                                    type,
                                                    action
                                                  ).then((res) => {
                                                    if (res.status == 200) {
                                                      setOpenOrder(
                                                        res.openorders
                                                      );
                                                    }
                                                  });
                                                  NotificationManager.success(
                                                    res.message
                                                  );
                                                } else {
                                                  NotificationManager.error(
                                                    res.message
                                                  );
                                                }
                                              })
                                              .catch((e) => {
                                                console.log("error: ", e);
                                              });
                                          }}
                                        >
                                         <FiDelete className="text-danger fs-14" />
                                        </div>
                                      </p>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={9}
                                  className="text-center border-bottom-0 text-muted"
                                >
                                  <img
                                    src="/img/no-data.png"
                                    className="no-data-found"
                                  />
                                  <p>No records</p>
                                </td>
                              </tr>
                            )}

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                        {total > itemsPerPage && (
                          <div className="d-flex justify-content-center mt-3">
                            <button
                              className="action-btn me-2"
                              onClick={handlePreviousPage}
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>
                            <button
                              className="action-btn"
                              onClick={handleNextPage}
                              disabled={currentPage * itemsPerPage >= total}
                            >
                              Next
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="order_history">
                    <div className="row cstm_form mb-4">
                      <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e) => {
                            setPairCurrency(e.target.value);
                          }}
                        >
                          <option value="all" selected>
                            All
                          </option>
                          {pair}
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e) => {
                            setSide(e.target.value);
                          }}
                        >
                          <option value="all" selected>
                            All
                          </option>
                          <option value="sell">Sell</option>
                          <option value="buy">Buy</option>
                        </select>
                      </div>
                      {/* <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e) => {
                            setType(e.target.value);
                          }}
                        >
                          <option value="all" selected>
                            All{" "}
                          </option>
                          <option value="limit">Limit Order</option>
                          <option value="market">Market Order</option>
                        </select>
                      </div> */}

                      <div className="col-lg-2 mb-2">
                        <a href="#" className="btn btn-pink" onClick={downloadCSV}>
                          Save As CSV
                        </a>
                      </div>
                    </div>
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                          <tr>
                              <th>Future Pairs</th>
                              <th>Trade Type</th>
                              <th>Order Type</th>
                              <th>Direction</th>
                              <th>Filled Value</th>
                              <th>Filled Price</th>
                              <th>Filled Qty</th>
                              <th>Filled Executed</th>
                              <th>Fee</th>
                              <th>Leverage</th>
                              <th>Filled Time</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}

                            {openorder && openorder.length > 0 ? (
                              openorder.map((item) => {
                                if (
                                  item.order_status == 1 || item?.order_status == 3 ||
                                  (item.order_status == 2 &&
                                    Number(item.total_executed) > 0)
                                )
                                  return (
                                    <tr>
                                    <td className="border-bottom-0 text-muted">
                                    <p>
                                        {item?.currency_type.toUpperCase() +
                                          "/" +
                                          item?.compare_currency.toUpperCase()}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                        Future
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{item?.order_type.toUpperCase()}</p>
                                    </td>
                                    <td className={`border-bottom-0 text-muted${side === "buy" ? " text-green" :side === "sell"?" text-red":""}`}>
                                      <p>{side}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(parseFloat(item?.volume) * item?.raw_price)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(item?.raw_price)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(item?.volume)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(item?.total_executed)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                      {round(item?.fees?item?.fees:0)}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                      {round(item?.leverage)}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                      {new Date(Number(item?.order_date)).toLocaleString()}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-danger">
                                      <p>
                                        {item?.order_status === 3
                                          ? "Expired"
                                          : Number(item?.volume) === Number(item?.total_executed)
                                          ? "Completed"
                                          : "Cancel"}
                                      </p>
                                    </td>

                                  </tr>
                                  );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={9}
                                  className="text-center border-bottom-0 text-muted"
                                >
                                  <img
                                    src="/img/no-data.png"
                                    className="no-data-found"
                                  />
                                  <p>No records</p>
                                </td>
                              </tr>
                            )}

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                        {total > itemsPerPage && (
                          <div className="d-flex justify-content-center mt-3">
                            <button
                              className="action-btn me-2"
                              onClick={handlePreviousPage}
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>
                            <button
                              className="action-btn"
                              onClick={handleNextPage}
                              disabled={currentPage * itemsPerPage >= total}
                            >
                              Next
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="trade_history">

                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                          <tr>
                              <th>Future Pairs</th>
                              <th>Trade Type</th>
                              <th>Order Type</th>
                              <th>Direction</th>
                              <th>Filled Value</th>
                              <th>Filled Price</th>
                              <th>Filled Qty</th>
                              <th>Leverage</th>
                              
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}

                            {openorder && openorder.length > 0 ? (
                              openorder.map((item) => {
                                return (
                                  <tr>
                                 <td className="border-bottom-0 text-muted">
                                    <p>
                                        {item?.currency_type.toUpperCase() +
                                          "/" +
                                          item?.compare_currency.toUpperCase()}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                        Future
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{item?.order_type.toUpperCase()}</p>
                                    </td>
                                    <td className={`border-bottom-0 text-muted${side === "buy" ? " text-green" :side === "sell"?" text-red":""}`}>
                                      <p>{side}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(parseFloat(item?.volume) * item?.raw_price)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(item?.raw_price)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(item?.volume)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                      {round(item?.leverage)}
                                      </p>
                                    </td>
                                </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={9}
                                  className="text-center border-bottom-0 text-muted"
                                >
                                  <img
                                    src="/img/no-data.png"
                                    className="no-data-found"
                                  />
                                  <p>No records</p>
                                </td>
                              </tr>
                            )}

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                        {total > itemsPerPage && (
                          <div className="d-flex justify-content-center mt-3">
                            <button
                              className="action-btn me-2"
                              onClick={handlePreviousPage}
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>
                            <button
                              className="action-btn"
                              onClick={handleNextPage}
                              disabled={currentPage * itemsPerPage >= total}
                            >
                              Next
                            </button>
                          </div>
                        )}


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
