import React, { useEffect, useState } from "react";
import ExSide from "./components/ExSide";
import Header from "./HomeComp/Header";
import OrdersTab from "./components/Orders.tab";
import CandleGraph from "./components/CandleGraph";
import BuyNSell from "./components/BuyNSell";
import Preloader from "./components/PreLoader";
import { toggleFav } from "./redux/actions/coinDBAction";
import TradeTab from "./Trade.Tab";
import OrderSystemTab from "./Order.system.tab";
import "./exchange.css";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./HomeComp/Footer";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { calculatePercentageChange, getRound } from "./redux/helpers/helper_functions";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import SpotChart from "./components/TVChartContainer/SpotChart";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { GoBook } from "react-icons/go";
import Zoom from "@mui/material/Zoom";
import { IoIosArrowDropright } from "react-icons/io";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import BuyDSell from "./components/BuyDSell";
import OrdersTabFuture from "./components/Orders.tab.future";

export default function Exchange(props) {
  const dispatch = useDispatch();

  const { webData } = useSelector((state) => state.websiteDBReducer);
  const {
    coins_loading,
    wallet_loading,
    user_fav_pairing,
    pending_order_loading,
    close_order_loading,
    user_fav_loading,
    coins,
    vhl_data,
    paired_curency_price,
    campare_currency,
    currency_type,
  } = useSelector((state) => state.coinDBReducer);
  const { user, isLoggedIn } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = useState(true);
  const [isFav, setIsFav] = useState(false);
  const [current_price, currentPrice] = useState(0);
  const [volume, setVolume] = useState(0);
  const [high, setHigh] = useState(0);
  const [low, setLow] = useState(0);
  const [close, setClose] = useState(0);
  const [open, setOpen] = useState(0);
  const [per, setPercet] = useState(0);
  const [isSell, setIsSell] = useState("");
  if (!props?.match?.params?.id) props.history?.replace("/exchange/btc-inr");
  const coin = props.match.params.id.split("-");
  const [isOpen, setIsOpen] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const [isArrowUp, setIsArrowUp] = useState(false);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    document.body.classList.toggle("no-scroll", !isVisible); // Toggle no-scroll class on body
    setIsArrowUp(!isArrowUp);
  };
  const closeDropdown = () => {
    setIsVisible(false);
    document.body.classList.toggle("no-scroll", !isVisible); // Toggle no-scroll class on body
    setIsArrowUp(false);
  };

  useEffect(() => {
    let match = user_fav_pairing.find((d) => d === data.symbol.toUpperCase());
    setIsFav(match ? true : false);
  }, [...user_fav_pairing, ...coin]);
  const data = Object.values(coins).find((d) => {
    if (d.symbol === coin[0].toUpperCase()) {
      return d;
    }
  });

  useEffect(() => {
    let spred =
      coin[1].toUpperCase() == "BTC"
        ? data?.spread_btc
        : coin[1].toUpperCase() == "USDT"
        ? data?.spread_usdt
        : coin[1].toUpperCase() == "INR"
        ? data?.spread_inr
        : data?.spread_vrx;
    // Get the data corresponding to the matching key
    const matchingData = vhl_data[props.match.params.id];
    if (matchingData) {
      if (current_price >= matchingData.raw_price) {
        setIsSell("up");
      } else {
        setIsSell("down");
      }
      setVolume(matchingData.volume);
      setHigh(matchingData.high);
      setLow(matchingData.low);
      setClose(matchingData.close);
      setOpen(matchingData.open);
      const percentageChange = calculatePercentageChange(matchingData.open, matchingData.close);
      setPercet(percentageChange);
      currentPrice(matchingData.raw_price.toFixed(spred));
    } else {
      setVolume(0);
      setHigh(0);
      setLow(0);
      setClose(0);
      setOpen(0);
      setPercet(0);
      currentPrice(
        (
          Number(data?.current_price) /
          Number(
            paired_curency_price
              ? paired_curency_price[coin[1].toUpperCase()]
                ? paired_curency_price[coin[1].toUpperCase()]
                : 1
              : 1
          )
        ).toFixed(spred)
      );
    }
  }, [vhl_data, props?.match?.params?.id, paired_curency_price]);
  useEffect(() => {
    let match = 1;
    const browser_currency_pair = props?.match?.params?.id.split("-");
    let vll = Object.values(coins);

    if (vll.length) {
      vll.forEach((item) => {
        // console.log("not inr",browser_currency_pair[1].toUpperCase(), item.symbol);
        if (item.symbol === browser_currency_pair[0].toUpperCase()) {
          if (browser_currency_pair[1].toUpperCase() !== "INR") {
            vll.forEach((it) => {
              if (it.symbol === browser_currency_pair[1].toUpperCase()) {
                match = 0;
              }
            });
          } else {
            match = 0;
          }
        }
      });
      if (match === 1) {
        props.history?.replace("/");
      }
      // if (!coins) {
      //   let nurl = vll[0]
      //     ? vll[0].symbol + "-" + vll[0]?.pairing_currency
      //     : "btc-inrx";
      //   props.history?.replace("/exchange/btc-inrx");
      // }
      if (isLoggedIn) {
        if (
          coins_loading == false &&
          wallet_loading == false &&
          pending_order_loading == false &&
          close_order_loading == false &&
          user_fav_loading == false
        )
          document.title =
            props?.match?.params?.id?.toUpperCase() +
            " " +
            webData.website_title +
            " Exchange";
        setLoading(false);
      } else {
        if (coins_loading == false) setLoading(false);
      }
    }
  }, [coins_loading, wallet_loading, coins.length, user_fav_loading]);

  return (
    <>
      <div className="application">
        <Helmet>
          <meta
            name="description"
            content="Explore the FRX market for seamless and secure crypto trading. Dive into the world of FRX Crypto Trading and elevate your trading experience."
          />        
       <title>
       {` ${isSell === "up" ? "▲" : "▼"} ${current_price} | ${props?.match?.params?.id?.split("-").join("").toUpperCase()} | FRX Spot Trading `} 
      </title>

        </Helmet>
      </div>
      <Header {...props} />

      {loading ? (
        <Preloader />
      ) : (
        <div className="exchange_styled">
          <div class="exchange_wrapper">
            <div className="exchange_container">
              <div class="left-column">
                <div className="row">
                  <div className="col-lg-12">
                    <div class="currency-market ">
                      <div
                        class={`currency-header-left currency_header pointer`}
                      >
                        <div
                          class="currency-header-left-inner"
                          id="hide_section"
                          onClick={toggleVisibility}
                        >
                          <div class="d-flex align-items-center justify-content-between--">
                            <div>
                              <img
                                className="me-2 position-relative top9 market_coin_img"
                                src={data?.icon}
                                alt=""
                              />
                            </div>
                            <div>
                              <span className="token_name">
                                {props.match.params.id
                                  .toUpperCase()
                                  .replace("-", "/")}{" "}
                              </span>
                              <div className="text-lgray">({data?.name}) </div>
                            </div>
                          </div>
                          <div className="fs-4 ms-3">
                            {isArrowUp ? (
                              <AiOutlineMenuUnfold />
                            ) : (
                              <AiOutlineMenuFold />
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="currency-header-right">
                        <div className="currency-header-right-inner">
                          <div class="value-block">
                            <span class="heading">Last Price</span>
                            <span
                              class={`${
                                isSell === "up" ? "text-green" : "text-red"
                              } heading_value`}
                            >
                              {current_price}
                              {isSell === "up" ? (
                                <BsArrowUpShort size={18} className="mx-2" />
                              ) : (
                                <BsArrowDownShort size={18} className="mx-2" />
                              )}
                            </span>
                            <span
                              onClick={() => {
                                dispatch(
                                  toggleFav(
                                    user?.params
                                      ? user.params.user_id
                                      : user.user_id,
                                    (data?.symbol).toUpperCase()
                                  )
                                );
                                if (isLoggedIn) setIsFav(!isFav);
                              }}
                            ></span>
                          </div>

                          <div className="value-block">
                            <span className="heading">24H Change</span>
                            <span className="heading_value">
                              <span
                                className={
                                  per > 0
                                    ? "text-green"
                                    : per < 0
                                    ? "text-red"
                                    : data?.direction === "up"
                                    ? "text-green"
                                    : "text-red"
                                }
                                
                              >
                                {getRound(per?per:data?.price_change_percentage_1h)}%{" "}
                                {
                                  per > 0 ? (
                                      <BsArrowUpShort size={18} />
                                  ) : per < 0 ? (
                                      <BsArrowDownShort size={18} />
                                  ) : data?.direction === "up" ? (
                                      <BsArrowUpShort size={18} />
                                  ) : (
                                      <BsArrowDownShort size={18} />
                                  )
                              }


                              </span>
                            </span>
                          </div>

                          <div className="value-block">
                            <span className="heading">24H High</span>
                            <span className="heading_value">{getRound(high)}</span>
                          </div>

                          <div className="value-block">
                            <span className="heading">24H Low</span>
                            <span className="heading_value">{getRound(low)}</span>
                          </div>

                          <div className="value-block">
                            <span className="heading">
                              24H Turnover (
                              {coin[1]
                                ? coin[1].toUpperCase()
                                : campare_currency.toUpperCase()}
                              )
                            </span>
                            <span className="heading_value">
                              {getRound(volume * current_price)}
                            </span>
                          </div>
                          <div className="value-block">
                            <span className="heading">
                              24H Turnover ({" "}
                              {coin[0]
                                ? coin[0].toUpperCase()
                                : currency_type.toUpperCase()}{" "}
                              )
                            </span>
                            {/* <span className="heading_value">{getRound(((close-open)/open)*100)>0?getRound(((close-open)/open)*100):0}%</span> */}
                            <span className="heading_value">
                              {getRound(volume)}
                            </span>
                          </div>

                          {/* <div className="value-block">
                    <span className="heading">
                      Daily Interest Rate ({coin[0]?coin[0].toUpperCase():currency_type.toUpperCase()}/{coin[1]?coin[1].toUpperCase():campare_currency.toUpperCase()})
                    </span>
                    <span className="heading_value">{getRound(volume)*current_price/100}</span>
                  </div> */}
                          {/* <div className="value-block">
                  <span color="#929292" className="heading">
                    CLOSE
                  </span>
                  <span color="#1C1B21" className=" sc-bwzfXH izvMda">
                    {close}
                  </span>
                </div> */}
                          {/* <div className="value-block">
                  <span className="heading">AVG</span>
                  <span className="heading_value">
                    {data?.price_change_percentage_1h} %
                  </span>
                </div> */}
                        </div>
                        <div>
                          <Tooltip
                            TransitionComponent={Zoom}
                            TransitionProps={{ timeout: 600 }}
                            arrow
                            title="Spot Trading History"
                          >
                            <Link to="/SpotOrders">
                              <p className="mb-0">
                                <GoBook className="fs-5 me-3" />
                              </p>
                            </Link>
                          </Tooltip>
                        </div>
                      </div>
                    </div>

                    {isVisible && (
                      <div class="full_width_dropdown">
                        <div className="inner_wrapper">
                          <div className="left_wrapper">
                            <div className="exchange-pairs">
                              <ExSide
                                {...props}
                                exchange="exchange"
                                closeDropdown={closeDropdown}
                              />
                            </div>
                          </div>
                          <div
                            className="right_wrapper"
                            onClick={closeDropdown}
                          ></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div class="exchange_inner_container">
                  <div class="graph-column">
                    <SpotChart {...props} />
                  </div>
                  <div class="trade-column">
                    <div className="card">
                      <div className="card-header py-0 px-1 custom_pills">
                        <div className="nav nav-pills" id="nav-tab" role="tab">
                          <div
                            className="nav-item nav-link cursor_pointer active"
                            data-bs-target="#order_book"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                          >
                            Order Book
                          </div>
                          <div
                            className="nav-item nav-link cursor_pointer"
                            data-bs-target="#trade_histroy"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls="trade_histroy"
                            aria-selected="false"
                          >
                            Recent Trades
                          </div>
                        </div>
                      </div>

                      <div className="tab-content">
                        <div className="tab-pane show active" id="order_book">
                          <OrderSystemTab {...props} />
                        </div>
                        <div className="tab-pane" id="trade_histroy">
                          <TradeTab {...props} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                <OrdersTab {...props} />
                  
                </div>
              </div>
              <div class="right-column">
                <div className="gray_btn">
                  <span> Trade</span>
                </div>
                <BuyNSell {...props} />
              </div>
            </div>
          </div>

         
          <footer className="exchange-footer">
            <div className="container text-center">
              <span className="small  text-lgray mb-0">
                FRX Exchange © 2024. All Rights Reserved.
              </span>
            </div>
          </footer>
        </div>
      )}
    </>
  );
}
