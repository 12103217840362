// HeroSection.js

import React, { useEffect, useState } from "react";
import "./HeroSection.css"; // Import CSS file for styling
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import { N_DepositUpdate } from "../../redux/helpers/api_functions_new";
import { getCoinRate, getRound } from "../../redux/helpers/helper_functions";

const HeroSection = () => {
  const [total_inr, setTotalInr] = useState(0);
  const [total_usdt, setTotalUsdt] = useState(0);
  const [total_btc, setTotalBtc] = useState(0);
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  const { coins, wallet, paired_curency_price, wallet_loading } = useSelector(
    (state) => state.coinDBReducer
  );
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 10,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  let coins1 = Object.values(coins);

  useEffect(() => {
    if (!wallet_loading) {
      getWallets();
    }
  }, [wallet, coins, paired_curency_price]);

  function getWallets() {
    let total = 0;
    let final_data =
      wallet &&
      Object.keys(wallet).map((res, i) => {
        let wallet_type = wallet[res]?.symbol.toUpperCase();
        let rate = getCoinRate(coins, wallet_type);
        let inr_val = rate * getRound(wallet[res]?.balance);
        let inr_available =
          rate * getRound(wallet[res]?.balance - wallet[res]?.locked);
        let inr_locked = rate * getRound(wallet[res]?.locked);
        total = total + parseFloat(inr_val);
        return {
          id: wallet[res]?.id,
          deposit_limit: wallet[res]?.deposit_limit
            ? wallet[res].deposit_limit
            : 0,
          icon: wallet[res]?.icon,
          symbol: wallet_type,
          name: wallet[res]?.name,
          status: wallet[res]?.status,
          withdral_fee: wallet[res]?.withdrawal_fee,
          locked:
            Math.round(wallet[res]?.locked * 10000) / 10000 != 0
              ? Math.round(
                  Math.abs(wallet[res]?.locked ? wallet[res]?.locked : 0) *
                    10000
                ) / 10000
              : Math.round(wallet[res]?.locked * 100000000) / 100000000,
          address: wallet[res]?.wallet_address,
          balance:
            Math.round(wallet[res]?.balance * 10000) / 10000 != 0
              ? Math.round(wallet[res]?.balance * 10000) / 10000
              : Math.round(wallet[res]?.balance * 100000000) / 100000000,
          p2pbalance: wallet[res]?.p2p_balance ? wallet[res]?.p2p_balance : 0,
          avl_balance:
            Math.round(wallet[res]?.available * 10000) / 10000 != 0
              ? Math.round(
                  (wallet[res]?.balance - wallet[res]?.locked) * 10000
                ) / 10000
              : Math.round(
                  (wallet[res]?.balance - wallet[res]?.locked) * 100000000
                ) / 100000000,
          inr_total_balance: getRound(inr_val),
          inr_avail_balance: getRound(inr_available),
          inr_locked_balance: getRound(inr_locked),
        };
      });
    setTotalInr(getRound(total));
    setTotalUsdt(getRound(total / paired_curency_price.USDT));
    setTotalBtc(getRound(total / paired_curency_price.BTC));
  }
  const market_child =
    coins1 &&
    coins1.map((item) => {
      if (item.symbol != "INR")
        return (
          <>
            <div className="img_backdrop">
              <img src={item.icon} />
            </div>
          </>
        );
    });
  return (
    <div className="main-content-fold">
      <div className="container">
        <div className="row align-items-center-- mt-5">
          <div className="col-lg-6">
            <div className="hero-text">
              <h1 className="poppins-bold text-uppercase">
                Leading the Future of Digital Currency
              </h1>
              <p>
                Discover a trusted platform for individuals and businesses to
                buy, trade, and manage cryptocurrencies seamlessly. FRX Crypto
                Exchange is your premier gateway to the evolving world of
                digital assets.
              </p>
            </div>

            {isLoggedIn ? (
              <div className="">
                <div className="">
                  <div className="">
                    <div className="page-content-title">
                      <h3 className="">Funding Wallet</h3>
                    </div>

                    <div className="col-lg-12">
                      <div className="flex_row mb-4">
                        <div className="">
                          <div class="">
                            <span class="desc_card">{total_inr}</span>
                            <span class="fs-16 text-muted ms-1">INR</span>
                            <span class="fs-16 text-lgray">
                              <span>≈ {total_usdt}</span>
                              <span>USD</span>
                            </span>
                            <span class="fs-16 text-pink ms-2">
                              <span class="interests_SddH3">(Equity:</span>
                              <span>{total_btc} BTC</span>)
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex gap-3">
                <Link to="/exchange/btc-inr" className="action-btn">
                  Start Trading
                </Link>
                <Link to="/About" className="action-btn outline">
                  Know More
                </Link>
              </div>
            )}

            <div className="mt-5">
              <h5 className="fw-bold">
                More than {coins1 && coins1.length} trading pairs
              </h5>
            </div>
            <div className="pairs_wrapper p-0">
              <div className="container">
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                  autoPlaySpeed={1500}
                  keyBoardControl={true}
                  // customTransition="all .5"
                  // transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                  //  deviceType={this.props.deviceType}
                  dotListClass="custom-dot-list-style"
                  // itemClass="carousel-item-padding-40-px"
                >
                  {market_child}
                </Carousel>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src="/img/candlestick.png"
              className="img-fluid d-none d-lg-block"
            />
          </div>
        </div>
      </div>

      {/* <div className="main-content">
       
      </div> */}
    </div>
  );
};

export default HeroSection;
