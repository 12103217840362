import React from "react";

const Loader = () => {
  return (
    <div
      style={{
        backgroundColor: "#000",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        width="150"
        height="150"
      >
        {/* Background Circle */}
        <circle
          cx="50"
          cy="50"
          r="45"
          fill="none"
          stroke="#1c1c1c"
          strokeWidth="5"
        />
        {/* Rotating Outer Ring */}
        <circle
          cx="50"
          cy="50"
          r="40"
          fill="none"
          stroke="#ffd700"
          strokeWidth="4"
          strokeDasharray="251"
          strokeDashoffset="200"
          strokeLinecap="round"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 50 50"
            to="360 50 50"
            dur="1.5s"
            repeatCount="indefinite"
          />
        </circle>
        {/* Pulsating Coin */}
        <g transform="translate(50, 50)">
          <circle cx="0" cy="0" r="10" fill="#ffd700">
            <animate
              attributeName="r"
              values="10;12;10"
              dur="1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="opacity"
              values="1;0.6;1"
              dur="1s"
              repeatCount="indefinite"
            />
          </circle>
          {/* Coin Text */}
          <text
            x="0"
            y="3"
            fill="#000"
            fontSize="8px"
            fontFamily="Arial, sans-serif"
            fontWeight="bold"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            FRX
          </text>
        </g>
        {/* Loading Text */}
        <text
          x="50%"
          y="85%"
          fill="#ffd700"
          fontSize="10px"
          fontFamily="Arial, sans-serif"
          textAnchor="middle"
        >
        </text>
        {/* Glowing Effect */}
        <circle
          cx="50"
          cy="50"
          r="47"
          fill="none"
          stroke="#ffd700"
          strokeWidth="0.5"
          opacity="0.5"
        >
          <animate
            attributeName="r"
            values="47;50;47"
            dur="2s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="opacity"
            values="0.5;0.1;0.5"
            dur="2s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </div>
  );
};

export default Loader;
