import * as React from "react";
import Datafeed from "./api/";
import { widget } from "../../../charting_library";
import { useSelector } from "react-redux"; // Import useSelector
import binanceAPI from "../../chart/services/api";
import binanceSpotAPI from "../../chart/spot/api";

import { useDispatch } from "react-redux";

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function injectCustomStyles(theme) {
  const iframe = document.querySelector("iframe");
  if (iframe) {
    iframe.addEventListener("load", () => {
      const iframeDoc = iframe.contentDocument;
      const iframeHead = iframeDoc.head;
      const style = iframeDoc.createElement("style");
      style.type = "text/css";
      style.innerHTML = `
        :root {
          --tv-color-pane-background: ${
            theme === "dark" ? "#16171A" : "#FFFFFF"
          } !important;
        }
        .chart-widget-popup .popup-header, .widgetbar .header {
          background-color: ${
            theme === "dark" ? "#16171A" : "#F0F0F0"
          } !important;
          color: ${theme === "dark" ? "#FFFFFF" : "#16171A"} !important;
        }
      `;
      iframeHead.appendChild(style);
    });
  }
}

export function TVChartContainer(props) {
  const switch_theme = useSelector((state) => state.AuthReducer.switch_theme); // Get theme from Redux store
  const { coins, trade_history } = useSelector((state) => state.coinDBReducer);
  const [width, setWidth] = React.useState(500);
  const coin = props.match.params.id.split("-");
  let dispatch = useDispatch();
  const [defaultProps, setDefaultProps] = React.useState({
    symbol: "Coinbase:BTC/USD",
    interval: "1D",
    containerId: "tv_chart_container",
    libraryPath: "/charting_library/",
    chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    userId: "public_user_id",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  });
  React.useEffect(() => {
    if (props?.tokenName?.length > 0) {
      localStorage.removeItem("symbol");
      localStorage.setItem("symbol", props?.tokenName);
      const temp = {
        ...defaultProps,
        symbol: `${props?.tokenName}`,
      };
      setDefaultProps(temp);
    }
  }, [props?.tokenName]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setWidth(parseInt(window.screen.width));
  }, []); // Empty dependency array to run once on mount

  React.useEffect(() => {
    let usdtData = Object.values(coins).find((item) => item.symbol == "USDT");
    let couins_data = Object.values(coins).find(
      (item) => item.symbol == coin[0].toUpperCase()
    );
    const bfAPI = new binanceAPI({
      debug: false,
      coin: coin,
      price: usdtData?.current_price,
      dispatch: dispatch,
      trade_history: trade_history,
    });
    const bffAPI = new binanceSpotAPI({ debug: false });
    const widgetOptions = {
      theme: switch_theme == "dark" ? "dark" : "light",
      debug: false,
      symbol:
        couins_data?.token_type == "self" || coin[0].toUpperCase() == "USDT"
          ? `${defaultProps.symbol.split("/").join("").toUpperCase()}`
          : coin[1] == "inr" || coin[1] == "INR"
          ? coin[0]?.toUpperCase() + "USDT"
          : `${defaultProps.symbol.split("/").join("").toUpperCase()}`,
      datafeed:
        coin[0].toUpperCase() == "USDT"
          ? Datafeed
          : couins_data?.token_type == "self"
          ? bffAPI
          : bfAPI,
      interval: defaultProps.interval,
      container_id: defaultProps.containerId,
      library_path: defaultProps.libraryPath,
      locale: getLanguageFromURL() || "en",
      // enabled_features: ['study_templates'],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      // user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      disabled_features: [
        "volume_force_overlay",
        // "left_toolbar",
        "pane_context_menu",
        "use_localstorage_for_settings",
        "timeframes_toolbar",
        "header_compare",
        "header_saveload",
        "header_undo_redo",
        "header_settings",
        // "header_fullscreen_button",
        "trading_notifications",
        "header_symbol_search",
      ],
      enabled_features: [
        "left_toolbar",
        width < 450 ? "" : "hide_left_toolbar_by_default",
      ],
      overrides: {
        "paneProperties.background":
          switch_theme == "dark" ? "#101014" : "#FFFFFF",
      },
    };
    const tvWidgetBase = new widget(widgetOptions);
    let tvWidget = tvWidgetBase;

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        // const button = tvWidget.createButton();
        // button.setAttribute("title", "Click to show a notification popup");
        // button.classList.add("apply-common-tooltip");
        // button.addEventListener("click", () =>
        //   tvWidget.showNoticeDialog({
        //     title: "Notification",
        //     body: "TradingView Charting Library API works correctly",
        //     callback: () => {
        //       console.log("Noticed!");
        //     },
        //   })
        // );
        // button.innerHTML = "Check API";
      });
    });
    injectCustomStyles(switch_theme); // Call injectCustomStyles with theme

    return () => {
      if (tvWidget !== null) {
        tvWidget.remove();
        tvWidget = null;
      }
    };
  }, [defaultProps, width, switch_theme]);

  return <div id={defaultProps.containerId} className={"TVChartContainer"} />;
}
