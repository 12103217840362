import React from "react";
import { CiMoneyCheck1 } from "react-icons/ci";
import { HiOutlineGift } from "react-icons/hi";
import { IoShareSocialOutline } from "react-icons/io5";
import { SlUser } from "react-icons/sl";
import { TbUserShield } from "react-icons/tb";
// import "./Process.css"; // Import CSS file for styling

const Process = () => {
  return (
    <>
      <div className="spacer">
        <div className="container">
          <h2 className="fw-bold text-center">FRX Beginner's Guide</h2>
          <h3 className="text-center fs-5">How do I trade on this platform?</h3>
          <div className="row d-flex align-center justify-content-center mt-5">
            <div className="col-lg-3 col-md-6 col-12 padding_0">
              <div className="card bg-transparent border-0 text-center">
                <div className="card-body padding_5">
                  <div class="text-center mb-3">
                    <div class="vtimeline_no active"> 1</div>
                    <div class="vtimeline_right"></div>
                  </div>

                  <h5 className="refer_heading">
                    <SlUser className="fs-4" /> Sign up{" "}
                  </h5>
                  <p className="text-lgray">
                    Register for an account on FRX Exchange or log in if
                    you're already a member.
                  </p>
                  <div className="custom_badge bg-soft-gray">
                  Enter email and password
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 padding_0">
              <div className="card bg-transparent border-0 text-center">
                <div className="card-body padding_5">
                  <div class="text-center mb-3">
                    <div class="vtimeline_left"></div>
                    <div class="vtimeline_no "> 2</div>
                    <div class="vtimeline_right"></div>
                  </div>

                  <h5 className="refer_heading">
                    {" "}
                    <TbUserShield className="fs-4" /> Complete KYC
                  </h5>
                  <p className="text-lgray">
                  To ensure the security and legitimacy of our services, we require you to complete the Know Your Customer (KYC) process
                  </p>
                  <div className="custom_badge bg-soft-gray"> Required information and documents </div> 
                             </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 padding_0">
              <div className="card bg-transparent border-0 text-center">
                <div className="card-body padding_5">
                  <div class="text-center mb-3">
                    <div class="vtimeline_left"></div>
                    <div class="vtimeline_no "> 3</div>
                    <div class="vtimeline_right"></div>
                  </div>

                  <h5 className="refer_heading">
                    {" "}
                    <HiOutlineGift className="fs-4" /> Get Reward
                  </h5>
                  <p className="text-lgray">
                    As your referrals begin trading on BITHAVEN Exchange, you'll
                    reward commissions on every trade they make.
                  </p>
                  <div className="custom_badge bg-soft-gray">
                  ₹1000 worth of BTHN + 100000 SHIB
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 padding_0">
              <div className="card bg-transparent border-0 text-center">
                <div className="card-body padding_5">
                  <div class="text-center mb-3">
                    <div class="vtimeline_left"></div>
                    <div class="vtimeline_no "> 4</div>
                  
                  </div>

                  <h5 className="refer_heading">
                    {" "}
                    <IoShareSocialOutline  className="fs-4" /> Start Referring
                  </h5>
                  <p className="text-lgray">
                  Share your referral link via social media, email, or any
                    other platform where your audience resides.
                  </p>
                  <div className="custom_badge bg-soft-gray">
                  ₹100 worth of BTHN on each referral
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="spacer">
        <div className="container">
          <div className="row">
            <div className="col-lg order-1 order-lg-1 d-flex flex-column align-items-lg-center justify-content-lg-center">
              <div className="container ">
                <div className="row process_wrapper">
                  <div className="col-lg-3">
                    <div className="process_img">
                      <img src="/img/add-user.png" className="img-fluid" />
                    </div>
                    <h4>1. Create an Account</h4>
                    <p>
                      Sign up for an account by providing your email address,
                      creating a password, and completing any required
                      verification steps.
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <div className="process_img">
                      <img src="/img/link.png" className="img-fluid" />
                    </div>
                    <h4>2. Complete KYC</h4>
                    <p>
                      To ensure the security and legitimacy of our services, we
                      require you to complete the Know Your Customer (KYC)
                      process by Submiting the required documents
                    </p>
                  </div>

                  <div className="col-lg-3">
                    <div className="process_img">
                      <img src="/img/savings.png" className="img-fluid" />
                    </div>
                    <h4>3. Get Reward</h4>
                    <p>
                      Once your buy order is placed, it will be matched with a
                      sell order from another user. The trade will be executed,
                      and the purchased cryptocurrency will be added to your
                      account
                    </p>
                    <div class="custom_badge bg-soft-gray">
                      21 BTHN + 100000 SHIB
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="process_img">
                      <img src="/img/chart.png" className="img-fluid" />
                    </div>
                    <h4>4. Start Referring</h4>
                    <p>
                      Sign up for an account by providing your email address,
                      creating a password, and completing any required
                      verification steps.
                    </p>
                    <div class="custom_badge bg-soft-gray">
                      21 BTHN + 100000 SHIB
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Process;
