import React, { useEffect, useState } from "react";
import Header from "./HomeComp/Header";
import ProfileSidebar from "./components/ProfileSidebar";
import Compressor from 'compressorjs';
import { NotificationManager } from "react-notifications";
import {
  BiCopy,
  BiError,
  BiMobileAlt,
  BiSolidCheckShield,
} from "react-icons/bi";
import { AiOutlineCamera, AiOutlineCloudUpload } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import {
  N_checkKYCStatus,
  N_profileData,
  N_uploadID,
  N_panVerify,
  N_sendAADHAROTPMobile,
  N_verifyAADHAROTPMobile,
} from "./redux/helpers/api_functions_new";
import { useSelector } from "react-redux";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import { FaArrowLeft, FaCheckCircle } from "react-icons/fa";
import { Link } from "@mui/material";
import { LuSendHorizonal } from "react-icons/lu";
import OTPInput from "react-otp-input";
import { isPhone } from "./redux/helpers/form-validator.functions";
import Tesseract from 'tesseract.js';
import { Spinner } from "react-bootstrap";

// import Footer from "./HomeComp/Footer";
export default function AccountVerification(props) {
  const { user, profile } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isverified, setIsverified] = useState(0);
  const [usercountry, setUserCountry] = useState("india");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [id_no, setIdNo] = useState("");
  const [id_Pan, setIdPan] = useState("");
  const [id_Aadhar, setIdAadhar] = useState("");
  const [id_Email, setIdEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [annual_income, setAnnualIncome] = useState("");
  const [job_profesion, setJobProfession] = useState("");
  const [last_name, setLastName] = useState("");
  const [type, setType] = useState("");
  const [personalTab, setPersonalTab] = useState(0);
  const [isSubmitPersonalData, setSubmitPersonalData] = useState(0);
  const [isManualData, setManualData] = useState(0);
  const [otp, setOtp] = useState(null);
  const [client_id, setClientId] = useState();
  const [isAadharVerified, setIsAadharVerified] = useState(0);
  const [mobile_no, setMobile_no] = useState(null);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [aadharData, setAadharData] = useState();
  const token = user?.params ? user.params.token : user.token;
  const [userDetails, setUserDetails] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  const history = useHistory();

  const handleSkipClick = (e) => {
    e.preventDefault();
    // Redirect to the home page
    history.push("/AccountSecurity"); // Change the path as needed
  };

  const verifyPan = () => {
    setLoading(true);
    try {
      N_panVerify(id_Pan, annual_income, job_profesion, token).then((res) => {
        if (res.status === 200) {
          setIsverified(2);
          setType("aadhar");
          setSuccessMessage(res.message);
          setTimeout(() => {
            setSuccessMessage("");
          }, 4000);
        } else {
          setErrorMessage(res.message);
        }
        setLoading(false);
        
      

      });
    } catch (error) {
      console.log(error.message);
      setErrorMessage(error.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 4000);
    }
  };

  const handleAadhar = () => {
    try {
      setLoading1(true);
      N_sendAADHAROTPMobile(id_no, token).then((res) => {
        if (res.status === 200) {
          setClientId(res.result);
          setIsAadharVerified(1);
        } else {
          setIsAadharVerified(2);
        }
        setLoading1(false);
        setSuccessMessage(res.message);
        setTimeout(() => {
          setSuccessMessage("");
        }, 4000);
      });
    } catch (error) {
      console.log(error.message);
      setErrorMessage(error.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 4000);
    }
  };

  const handleAadharOtpVerification = () => {
    try {
      setLoading2(true);
      N_verifyAADHAROTPMobile(client_id, otp, token).then((res) => {
        if (res.status === 200) {
          setAadharData(res.result);
          setIsverified(1);
          setVerificationSuccess(true);
        }
        setLoading2(false);
        setSuccessMessage(res.message);
        setTimeout(() => {
          setSuccessMessage("");
        }, 4000);
      });
    } catch (error) {
      console.log(error.message);
      setErrorMessage(error.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 4000);
    }
  };
  const [id_verification, setIdVerifocation] = useState(0);
  const [filedata, setFileData] = useState();
  useEffect(() => {
    N_checkKYCStatus(user?.params ? user.params.token : user.token).then(
      (d) => {
        if (d.status === 200) {
          if (d?.params.status == 2) {
            setIsverified(2);
          } else if (d?.params.status == 1) {
            setIsverified(1);
            setUserDetails(d.params);
          } else if(d?.params.status == 3) {
            setIsverified(2);
            setIsAadharVerified(2);
          } else if(d?.params.status == -1) {
            setIsverified(-1);
          } else if(d?.params.status == 4){
            setIsverified(2);
            setIsAadharVerified(2);
            setIdVerifocation(1);
          }
        }
      }
    );
  }, [user]);

  useEffect(() => {
    const token = user?.params ? user.params.token : user.token;
    N_checkKYCStatus(token).then((d) => {
      if (d.status === 200) {
        setType(d.params.doc);
      }
    });
  }, []);

  const updatePersonal = (e) => {
    e.preventDefault();
    N_profileData(
      user?.params ? user.params.token : user.token,
      type,
      id_no,
      id_Pan,
      first_name,
      last_name,
      usercountry
    ).then((res) => {
      console.log(res);
      if (res.status == 200) {
        setSubmitPersonalData(2);
      }
    });
  };

  function readURL(input, val, id) {
    const file = input.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    var reader = new FileReader();
    if (file) {
      const fileSizeInBytes = file.size;
      const fileSizeInKB = fileSizeInBytes / 1024;
      const fileSizeInMB = fileSizeInKB / 1024;
     
      let ql=0.8;
      if(fileSizeInMB>=1 && fileSizeInMB<3){
        ql=0.6;
      } else if(fileSizeInMB>=3 && fileSizeInMB<6){
        ql=0.4
      } else if(fileSizeInMB>=6 && fileSizeInMB<10){
        ql=0
      } else if(fileSizeInMB>10) {
        NotificationManager.error("Please Enter picture below 5MB")
      }
      reader.readAsDataURL(file);
      new Compressor(file, {
        quality: ql, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.        
          setFileData(compressedResult);
        },
      });
      setIdVerifocation(val);
      reader.onload = function (e) {
        $("#"+id).attr("src", e.target.result);
      };
    }
  }

    const [recognizedText, setRecognizedText] = useState('');
    useEffect(() => {
      const recognizeText = async () => {
        if (selectedImage) {
          const result = await Tesseract.recognize(selectedImage);
          // Regular expression to match 12-digit Aadhar number
          const aadharRegex = /\b\d{4}\s\d{4}\s\d{4}\b/g;

          // Extract Aadhar numbers from the text
          const aadharNumbers = result.data.text.match(aadharRegex);
          setRecognizedText(aadharNumbers[0]);
          // Output the Aadhar numbers found
        }
      };
      recognizeText();
    }, [selectedImage]);

  return (
    <>
      <Header {...props} />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <ProfileSidebar />
        </div>

        <main className="s-layout__content">
          <div className="dashboard_header">
            <h3 className="header_title">Individual Identity Verification</h3>
          </div>

          <div className="container-fluid">
            <div className="account_page">
              <div class="row">
                <div class="col-lg-8">
                  <div className="col-lg-12 mb-5">
                    <div className=" my-4">
                      {isverified === 0 || isverified === 2 || isverified === 3 ? (
                        <>
                          <h4 className="h5 mb-0">
                            Verify your identity for enhanced account security
                            and expanded service access.
                          </h4>
                          <div className="text-lgray">
                            The verification only takes 1-2 minutes.
                          </div>
                          <button
                            data-bs-toggle="modal"
                            data-bs-target="#kyc_modal"
                            className="btn btn-primary px-5 rounded-pill"
                          >
                            Verify
                          </button>
                        </>
                      ) : isverified === 1 ? (
                        <>
                          <h4 className="h5"></h4>
                          <div className="btn btn-success rounded-pill pe-none">
                            <div className="d-flex align-items-center gap-2 fs-16">
                              <span>
                                <FaCheckCircle />
                              </span>
                              Verified
                            </div>
                          </div>
                        </>
                      ) : isverified==-1?(
                          <>
                            <h4 className="h5"></h4>
                            <div className="btn btn-success rounded-pill pe-none">
                              <div className="d-flex align-items-center gap-2 fs-16">
                                Pending
                              </div>
                            </div>
                          </>
                      ): null}
                    </div>
                  </div>

                  {isverified === 1 ? (
                    <>
                      <h5> Your identity has been verified</h5>
                      {userDetails &&
                      userDetails.doc_2_no.length > 0 &&
                      userDetails.doc_2_type === "pancard" ? (
                        <div>
                          <div className="mt-3">
                            <div className="">
                              <div className="card-body">
                                <div className="row mt-2 mb-2">
                                  <h6 className="col-md-12 page-title">
                                    {`Your ${
                                      userDetails &&
                                      userDetails.doc_2_type
                                        .charAt(0)
                                        .toUpperCase() +
                                        userDetails.doc_2_type.slice(1)
                                    } has been verified`}
                                  </h6>
                                </div>

                                <div className="row">
                                <div className="table-responsive">
                                    <table className="table table-bordered kyctable">
                                      <thead className="bg-light">
                                        <tr>
                                          <th className="tdCenter">Name</th>
                                          <th className="tdCenter">
                                            Pan Card Number
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="tdCenter">
                                            {userDetails && userDetails.name}
                                          </td>
                                          <td className="tdCenter">
                                            {userDetails &&
                                              userDetails.doc_2_no}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {userDetails &&
                      userDetails.doc_1_no.length > 0 &&
                      userDetails.doc_1_type === "aadhar" ? (
                        <div>
                          <div className="mt-3">
                            <div className="card--">
                              <div className="card-body">
                                <div className="row mt-2 mb-2">
                                  <h6 className="col-md-12 page-title">
                                    {`Your ${
                                      userDetails &&
                                      userDetails.doc_1_type
                                        .charAt(0)
                                        .toUpperCase() +
                                        userDetails.doc_1_type.slice(1)
                                    } has been verified`}
                                  </h6>
                                </div>

                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-bordered align-middle kyctable">
                                      <thead className="bg-light">
                                        <tr>
                                          <th className="text-center">Image</th>
                                          <th className="tdCenter">Name</th>
                                          <th className="tdCenter">
                                            Aadhar Card Number
                                          </th>
                                          <th className="tdCenter">Address</th>
                                          <th className="tdCenter">
                                            Date of Birth
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="text-center">
                                            <img
                                              src={
                                                userDetails &&
                                                userDetails.doc_1_s
                                              }
                                              className="kyc-user-photo"
                                            />
                                          </td>
                                          <td className="tdCenter">
                                            {userDetails && userDetails.name}
                                          </td>
                                          <td className="tdCenter">
                                            {userDetails &&
                                              userDetails.doc_1_no}
                                          </td>
                                          <td className="tdCenter">
                                            {userDetails &&
                                              userDetails.doc_1_address}
                                          </td>
                                          <td className="tdCenter">
                                            {userDetails &&
                                              new Date(
                                                Number(userDetails.doc_1_dob)
                                              ).toLocaleDateString(
                                                "en-IN",
                                                options
                                              )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div
                      class="accordion verification_accrodion"
                      id="accordionExample"
                    >
                      <div class="timeline_container">
                        <div>
                          <div class="accor_timeline_no">
                            <i class="fa-solid fa-check text-green"></i>
                          </div>
                          <div class="accor_timeline_line"></div>
                        </div>

                        <div class="accordion-item border-0">
                          <div class="">
                            <h2 class="accordion-header" id="headingOne">
                              <button
                                class="accordion-button p-0"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                <div> Successfully Signed Up</div>
                                <div className="small_heading">
                                  {" "}
                                  Daily withdrawal limit: 20,000 USDT.{" "}
                                </div>
                              </button>
                            </h2>

                            <div
                              id="collapseOne"
                              class="accordion-collapse collapse show"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body px-0">
                                <div className="jambotron py-3 mb-5">
                                  <div className="sub_heading">
                                    Account Features
                                  </div>

                                  <div className="col-md-12">
                                    <div className="row mt-1">
                                      <div className="col-lg-4 mb-3">
                                        <small className="text-lgray">
                                          Daily Withdrawal Limit
                                        </small>
                                        <h5 className="mb-0 fs-14">
                                          xxxxxxxxx
                                        </h5>
                                      </div>

                                      <div className="col-lg-4 mb-3">
                                        <small className="text-lgray">
                                          Fiat Deposit
                                        </small>
                                        <h5 className="mb-0 fs-14">
                                          xxxxxxxxx
                                        </h5>
                                      </div>
                                      <div className="col-lg-4 mb-3">
                                        <small className="text-lgray">
                                          Daily P2P Trading
                                        </small>
                                        <h5 className="mb-0 fs-14">
                                          xxxxxxxxx
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="timeline_container">
                        <div>
                          <div class="accor_timeline_no">
                            {isverified == 1 || isverified == 2 ? (
                              <i class="fa-solid fa-check text-green"></i>
                            ) : (
                              <i class="fa-solid">2</i>
                            )}
                          </div>
                          <div class="accor_timeline_line"></div>
                        </div>

                        <div class="accordion-item border-0">
                          <h2 class="accordion-header" id="headingTwo">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Verify Your Pan Card
                              <div className="small_heading">
                                Increase your daily withdrawal limit to 25,000
                                USDT.
                              </div>
                            </button>
                          </h2>

                          <div
                            id="collapseTwo"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body px-0">
                              <div className="jambotron py-3 mb-5">
                                <div className="sub_heading">
                                  Account Features
                                </div>

                                <div className="col-md-12">
                                  <div className="row mt-1">
                                    <div className="col-lg-4 mb-3">
                                      <small className="text-lgray">
                                        Daily Withdrawal Limit
                                      </small>
                                      <h5 className="mb-0 fs-14">xxxxxxxxx</h5>
                                    </div>

                                    <div className="col-lg-4 mb-3">
                                      <small className="text-lgray">
                                        Fiat Deposit
                                      </small>
                                      <h5 className="mb-0 fs-14">xxxxxxxxx</h5>
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                      <small className="text-lgray">
                                        Daily P2P Trading
                                      </small>
                                      <h5 className="mb-0 fs-14">xxxxxxxxx</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="timeline_container">
                        <div>
                          <div class="accor_timeline_no">
                            {isverified == 1 ? (
                              <i class="fa-solid fa-check text-green"></i>
                            ) : (
                              <i class="fa-solid">3</i>
                            )}
                          </div>
                        </div>

                        <div class="accordion-item border-0">
                          <h2 class="accordion-header" id="headingThree">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Verify Your AADHAR
                              <div className="small_heading">
                                Increase your daily withdrawal limit to
                                1,000,000 USDT.
                              </div>
                            </button>
                          </h2>

                          <div
                            id="collapseThree"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body px-0">
                              <div className="jambotron py-3 mb-5">
                                <div className="sub_heading">
                                  Account Features
                                </div>

                                <div className="col-md-12">
                                  <div className="row mt-1">
                                    <div className="col-lg-4 mb-3">
                                      <small className="text-lgray">
                                        Daily Withdrawal Limit
                                      </small>
                                      <h5 className="mb-0 fs-14">xxxxxxxxx</h5>
                                    </div>

                                    <div className="col-lg-4 mb-3">
                                      <small className="text-lgray">
                                        Fiat Deposit
                                      </small>
                                      <h5 className="mb-0 fs-14">xxxxxxxxx</h5>
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                      <small className="text-lgray">
                                        Daily P2P Trading
                                      </small>
                                      <h5 className="mb-0 fs-14">xxxxxxxxx</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div class="col-lg-4 identity_accordion">
                  <div class="jambotron-outline">
                    <div class="account_page_subheading mb-2">FAQs</div>
                    <div
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                          <button
                            class="accordion-button px-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            What is identity verification?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            Identity verification is a crucial due diligence
                            process that verifies customer information and
                            background to ensure compliance with anti-money
                            laundering (AML) and combating the financing of
                            terrorism (CFT) regulations. This process enhances
                            account security and reduces the risk of fraudulent
                            and illegal activities.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingTwo">
                          <button
                            class="accordion-button px-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            Why do I need to complete identity verification?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            {" "}
                            Identity verification is crucial to safeguard the
                            security and integrity of your account. By verifying
                            your personal information and background, we can
                            effectively prevent unauthorized use of your
                            identity for fraudulent or illegal purposes.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                          <button
                            class="accordion-button px-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            Which documents and information are required for
                            identity verification?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            For identity verification, you usually need to
                            provide proof of identity (e.g., passport, driver's
                            license, or ID card) and personal photographs.
                            Additional documents may be required in certain
                            cases. Rest assured that your information is
                            protected and used only for legal and compliance
                            purposes. For any questions about the identity
                            verification requirements of specific institutions,
                            contact them for accurate information.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button
                            class="accordion-button px-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapse4"
                            aria-expanded="false"
                            aria-controls="flush-collapse4"
                          >
                            Where can I find help for problems with identity
                            verification?
                          </button>
                        </h2>
                        <div
                          id="flush-collapse4"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-collapse4"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            If you encounter any problems with identity
                            verification, you can get help by joining our
                            Telegram group. Our customer service team will be
                            happy to answer any questions you may have and
                            provide further support. Please provide us with
                            information about your problems so that we can help
                            resolve your issue as quickly as possible.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      {/*=========== Modal of deposit menu==================== */}

      <div
        id="kyc_modal"
        class="modal fade"
        tabindex="-1"
        data-backdrop="static"
      >
        <div class="modal-dialog modal-lg-- modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0">
              <button
                type="button"
                className="btn"
                aria-label="Close"
                onClick={() => {
                  setSubmitPersonalData(0);
                }}
              >
                <IoIosArrowBack className="fs-4" />
              </button>
              <h4 class="modal-title">Identity Verification</h4>
              <button
                type="button"
                class="btn-close fs-14"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-4">
              <div className="" style={{ minHeight: "375px" }}>
                {/* Choose Types of verfication */}
                {personalTab == 0 ? (
                  <>
                    <div
                      id="verification_type"
                      className={isSubmitPersonalData == 0 ? "" : "d-none"}
                    >
                      <div class="mb-3 cstm_form">
                        <label className="text-muted fs-12 mb-1">
                          <span className="text-red">*</span>Country/Region
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setUserCountry(e.target.value);
                          }}
                        >
                          <option
                            value="india"
                            selected={usercountry == "india" ? "selected" : ""}
                          >
                            India
                          </option>
                          <option value="nepal">Nepal</option>
                        </select>
                      </div>

                      {usercountry == "India" || usercountry == "india" ? (
                        <div className="mb-5">
                          <label className="text-muted fs-12">
                            <span className="text-red">*</span>Verify With
                          </label>
                          {isverified === 2 ? (
                            <div
                              class="modal_select_options p-3"
                              onClick={(e) => {
                                setSubmitPersonalData(1);
                                setType("aadhar");
                              }}
                            >
                              <img
                                src="/img/id-card.svg"
                                alt="coin symbole"
                                class="deposit_imgs filter_gray"
                              />
                              <div class="content_div">
                                <div class="mb-0">Aadhar Card</div>
                              </div>{" "}
                              <span>
                                <i class="fa-solid fa-arrow-right-long ms-1"></i>
                              </span>
                            </div>
                          ) : isverified ==3 ? (
                            <div className="mb-5">
                            <div
                              class="modal_select_options p-3 mt-1"
                              onClick={(e) => {
                                setManualData(1);
                                setType("passport");
                              }}
                            >
                              <img
                                src="/img/passport.svg"
                                alt="coin symbole"
                                class="deposit_imgs filter_gray"
                              />
                              <div class="content_div">
                                <div class="mb-0">Passport</div>
                              </div>{" "}
                              <span>
                                <i class="fa-solid fa-arrow-right-long ms-1"></i>
                              </span>
                            </div>
                            <div
                              class="modal_select_options p-3"
                              onClick={(e) => {
                                setManualData(1);
                                setType("aadhar");
                              }}
                            >
                              <img
                                src="/img/id-card.svg"
                                alt="coin symbole"
                                class="deposit_imgs filter_gray"
                              />
                              <div class="content_div">
                                <div class="mb-0">Aadhar Card</div>
                              </div>{" "}
                              <span>
                                <i class="fa-solid fa-arrow-right-long ms-1"></i>
                              </span>
                            </div>
                            <div
                              class="modal_select_options p-3"
                              onClick={(e) => {
                                setManualData(1);
                                setType("driverlicense");
                              }}
                            >
                              <img
                                src="/img/dl.svg"
                                alt="coin symbole"
                                class="deposit_imgs filter_gray"
                              />
                              <div class="content_div">
                                <div class="mb-0">Driver's License</div>
                              </div>{" "}
                              <span>
                                <i class="fa-solid fa-arrow-right-long ms-1"></i>
                              </span>
                            </div>
                            </div>
                          ):(
                            <div
                              class="modal_select_options p-3"
                              onClick={(e) => {
                                setSubmitPersonalData(1);
                                setType("pan");
                              }}
                            >
                              <img
                                src="/img/id-card.svg"
                                alt="coin symbole"
                                class="deposit_imgs filter_gray"
                              />
                              <div class="content_div">
                                <div class="mb-0">Pan Card</div>
                              </div>{" "}
                              <span>
                                <i class="fa-solid fa-arrow-right-long ms-1"></i>
                              </span>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="mb-5">
                          <label className="text-muted fs-12">
                            <span className="text-red">*</span>Verify With
                          </label>
                          <div
                            class="modal_select_options p-3"
                            onClick={(e) => {
                              setSubmitPersonalData(1);
                              setType("id");
                            }}
                          >
                            <img
                              src="/img/id-card.svg"
                              alt="coin symbole"
                              class="deposit_imgs filter_gray"
                            />
                            <div class="content_div">
                              <div class="mb-0">ID Card</div>
                            </div>{" "}
                            <span>
                              <i class="fa-solid fa-arrow-right-long ms-1"></i>
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="fs-12 text-lgray">
                        <h6>Requirements ID</h6>

                        <ul>
                          <li>
                            1. Use your valid government-issued identification
                            document for verification.
                          </li>
                          <li>
                            2. Choose a different document type if you've
                            previously verified another account.
                          </li>
                          <li>
                            3. Ensure that the uploaded document is an original.
                            Please note that photocopies will not be accepted.
                          </li>
                          <li>
                            4. The following documents are not supported:
                            Student Visa, Working Visa, Travel Visa
                          </li>
                        </ul>

                        <h6 className="mt-3">Selfie</h6>
                        <ul>
                          <li>
                            1. Ensure proper lighting and a clutter-free
                            background.
                          </li>
                          <li>2. Frame your face prominently in the shot.</li>
                        </ul>
                      </div>
                    </div>

                    {/* Aadhar */}
                    {/* Fill Personal Info and ID Number Onmeta */}
                    {type === "aadhar" ? (
                      <div
                        className={isSubmitPersonalData == 1 ? "" : "d-none"}
                        id="personal_info"
                      >
                        <div class="steps_div">
                          <div class="d-flex align-items-center">
                            <div class="circle_no active">1</div>
                            <span class="text-lgray">Personal Information</span>
                          </div>
                          <div class="adjacent_line"></div>
                          <div class="d-flex align-items-center">
                            <div class="circle_no">2</div>
                            <span class="text-lgray">Aadhar Verification</span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          {isAadharVerified!==2?
                          <>
                          <div className="col-lg-12">
                            <p
                              className={
                                errorMessage
                                  ? "text-danger mb-0 fs-14"
                                  : "text-success mb-0 fs-14"
                              }
                            >
                              {errorMessage ? errorMessage : successMessage}
                            </p>
                          </div>

                          <p className="fs-12 text-lgray mb-3">
                            {" "}
                            An OTP will be sent to your phone number linked with
                            your Aadhar Card
                          </p>

                          <div className="cstm_form">
                            <div className="form-group">
                              <label htmlFor="id_no" className="text-lgray">
                                Aadhar Number
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control text-dark"
                                  id="id_no"
                                  name="id_no"
                                  value={id_no}
                                  onChange={(e) => {
                                    setIdNo(e.target.value);
                                  }}
                                />
                                <span className="input-group-text">
                                  <button
                                    className="btn border-0"
                                    disabled={loading1}
                                    onClick={() => {
                                      if (type === "aadhar") {
                                        handleAadhar();
                                      }
                                    }}
                                  >
                                    {loading1 ? (
                                      <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                                    ) : null}
                                    <LuSendHorizonal className="fs-4 text-lgray" />
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>
                          </>:null}

                          {isAadharVerified == 1 ? (
                            <div className="">
                              <div className="form-group mt-3">
                                {/* <label htmlFor="id_no">Verify Aadhar OTP</label> */}
                                <h5 className="hero_heading">
                                  Enter the 6- digit verification code
                                </h5>

                                <div className="mt-3">
                                  <OTPInput
                                    value={otp}
                                    id="id_no"
                                    name="id_no"
                                    onChange={(otp) => {
                                      if (/^\d+$/.test(otp) || otp === "") {
                                        setOtp(otp);
                                      }
                                    }}
                                    type="tel"
                                    pattern="[0-9]*"
                                    numInputs={6}
                                    renderSeparator={
                                      <span
                                        style={{
                                          fontSize: "7px",
                                          marginLeft: "8px",
                                          marginRight: "8px",
                                        }}
                                      ></span>
                                    }
                                    isDisabled={loading}
                                    renderInput={(inputProps, index) => (
                                      <input
                                        {...inputProps}
                                        key={index}
                                        style={{
                                          width: "40px",
                                          textAlign: "center",
                                          marginBottom: "10px",
                                          height: "40px",
                                          borderStyle: "solid",
                                          borderWidth: "1px",
                                          borderColor: "#ddd",
                                          color: "#000",
                                          fontWeight: 600,
                                          borderRadius: "5px",
                                          backgroundColor: "#fff",
                                          outline: "none",
                                        }}
                                      />
                                    )}
                                  />
                                </div>

                                {/* <input
                                  type="text"
                                  className="form-control"
                                  id="id_no"
                                  name="id_no"
                                  onChange={(e) => {
                                    setOtp(e.target.value);
                                  }}
                                /> */}
                                <button
                                  className="btn btn-primary mt-2"
                                  disabled={loading2}
                                  onClick={handleAadharOtpVerification}
                                >
                                  {loading2 ? (
                                    <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                                  ) : null}
                                  Verify OTP
                                </button>
                              </div>
                            </div>
                          ) : null}
                           {isAadharVerified == 2 ? (
                            <>
                            <div
                            className={id_verification == 0 ? "" : "d-none"}
                            id="upload_doc"
                          >
      
                            <div className="row mt-3">
                              <div className="col-lg-9 m-auto col-12 mb-3">
                                <div className="jambotron border-dashed text-center">
                                  <h6>Upload Front of Aadhar</h6>
                                  <div className="badge bg-dark fs-12 my-2">
                                    Step 1 of 2
                                  </div>
                                  <p>
                                    Upload a color image of the entire document.
                                    Screenshots are not allowed. JPG, JPEG or PNG
                                    format only.
                                  </p>
                                  <div className="d-grid">
                                    <input
                                      id="upload_front_adhar"
                                      type="file"
                                      onChange={(e) => {
                                        readURL(e, 0, "check_photo");
                                      }}
                                      className="form-control kyc_image--"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                        <div className="col-lg-9 m-auto col-12 mb-3">
                          
                          <div className="jambotron border-dashed">
                            <div className="photo-preview">
                              {" "}
                              <img
                                src="/img/placeholder-id-front.svg"
                                className="img-fluid"
                                id="check_photo"
                                alt="placeholder-id-front"
                              />
                            </div>
                            <h4 class="big_heading text-center">Check Photo</h4>

                            <ul className="my-3">
                              <li>
                                Have you checked if your ID is supported?{" "}
                              </li>
                              <li>Can you read the details?</li>
                              <li>Can you see the whole ID?</li>
                            </ul>
                            {!recognizedText?
                            <h6>Waiting For Image  <i className="loading-icon fas fa-spinner fa-spin mr-2"></i></h6>
                            :null}
                            <div className="text-center">
                              <button
                                className="btn btn-secondary my-2 me-2"
                                onClick={(e) => {
                                  setIdVerifocation(0);
                                }}
                              >
                                Try again
                              </button>
                              <button
                                className="btn btn-primary my-2"
                                disabled={recognizedText?"":"disabled"}
                                onClick={(e) => {
                                  N_uploadID(
                                    e,
                                    filedata,
                                    "docf",
                                    user?.params
                                      ? user.params.token
                                      : user.token,
                                      recognizedText
                                  );
                                  setIdVerifocation(1);
                                }}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                            
                          </div>

                          <div
                            className={id_verification == 1 ? "" : "d-none"}
                            id="upload_doc"
                          >
      
                            <div className="row mt-3">
                              <div className="col-lg-9 m-auto col-12 mb-3">
                                <div className="jambotron border-dashed text-center">
                                <h6>Upload Back of Aadhar</h6>
                                  <div className="badge bg-dark fs-12 my-2">
                                    Step 2 of 2
                                  </div>
                                  <p>
                                    Upload a color image of the entire document.
                                    Screenshots are not allowed. JPG, JPEG or PNG
                                    format only.
                                  </p>
                                  <div className="d-grid">
                                    <input
                                      id="upload_back_adhar"
                                      type="file"
                                      onChange={(e) => {
                                        readURL(e, 1, "check_photo1");
                                      }}
                                      className="form-control kyc_image--"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                        <div className="col-lg-9 m-auto col-12 mb-3">
                          <div className="jambotron border-dashed">
                            <div className="photo-preview">
                              {" "}
                              <img
                                src="/img/placeholder-id-front.svg"
                                className="img-fluid"
                                id="check_photo1"
                                alt="placeholder-id-front"
                              />
                            </div>
                            <h4 class="big_heading text-center">Check Photo</h4>

                            <ul className="my-3">
                              <li>
                                Have you checked if your ID is supported?{" "}
                              </li>
                              <li>Can you read the details?</li>
                              <li>Can you see the whole ID?</li>
                            </ul>

                            <div className="text-center">
                              <button
                                className="btn btn-secondary my-2 me-2"
                                onClick={(e) => {
                                  setIdVerifocation(1);
                                }}
                              >
                                Try again
                              </button>
                              <button
                                className="btn btn-primary my-2"
                                onClick={(e) => {
                                  N_uploadID(
                                    e,
                                    filedata,
                                    "docb",
                                    user?.params
                                      ? user.params.token
                                      : user.token
                                  );
                                }}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                            
                          </div>
                          </>
                          ) : null}
                          {aadharData ? (
                            <div id="personal_info">
                              <div className="row">
                                <h5 className="mb-3">Personal Info</h5>

                                <div className="col-lg-4 mb-3">
                                  <label htmlFor="user_fname">
                                    Name <span className="text-red">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="user_name"
                                    readOnly
                                    className="form-control"
                                    id="user_name"
                                    value={aadharData?.full_name}
                                  />
                                  <img
                                    src={`data:image/png;base64,${aadharData?.profile_image}`}
                                    height="150px"
                                    width="200px"
                                    id="aadhar_selfie"
                                    name="aadhar_selfie"
                                    alt="adhar pic"
                                  />
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <label htmlFor="gender">
                                    Gender <span className="text-red">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="gender"
                                    className="form-control"
                                    id="gender"
                                    readOnly
                                    value={
                                      aadharData?.gender == "M"
                                        ? "MALE"
                                        : "FEMALE"
                                    }
                                  />
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <label htmlFor="user_dob">
                                    DOB <span className="text-red">*</span>
                                  </label>
                                  <input
                                    type="date"
                                    name="user_dob"
                                    id="user_dob"
                                    readOnly
                                    className="form-control"
                                    value={aadharData?.dob}
                                  />
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <label htmlFor="user_address">
                                    Address <span className="text-red">*</span>
                                  </label>
                                  <textarea
                                    className="form-control"
                                    id="user_address"
                                    name="user_address"
                                    readOnly
                                    rows="1"
                                    value={
                                      aadharData?.address.house +
                                      " " +
                                      aadharData?.address.vtc +
                                      " " +
                                      aadharData?.address.po +
                                      " " +
                                      aadharData?.address.landmark +
                                      " " +
                                      aadharData?.address.loc
                                    }
                                  ></textarea>
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <label htmlFor="country">Country *</label>
                                  <input
                                    type="text"
                                    name="country"
                                    className="form-control"
                                    id="country"
                                    readOnly
                                    value={aadharData?.address.country}
                                  />
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <label htmlFor="state">State *</label>
                                  <input
                                    type="text"
                                    name="state"
                                    className="form-control"
                                    id="state"
                                    readOnly
                                    value={aadharData?.address.state}
                                  />
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <label htmlFor="country">City *</label>
                                  <input
                                    type="text"
                                    name="city"
                                    className="form-control"
                                    id="city"
                                    readOnly
                                    value={aadharData?.address.dist}
                                  />
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <label htmlFor="user_pincode">
                                    Pincode *
                                  </label>
                                  <input
                                    type="text"
                                    name="user_pincode"
                                    className="form-control"
                                    id="user_pincode"
                                    readOnly
                                    value={aadharData?.zip}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>

                        <div className="d-grid">
                          <button
                            className={`${
                              verificationSuccess ? "" : "disabled"
                            } btn btn-light py-2 shadow-sm `}
                            data-bs-dismiss="modal"
                            onClick={(e) => {
                              N_checkKYCStatus(token).then((res) => {
                                if (res.status === 200) {
                                  if (res?.params.status == 1) {
                                    setIsverified(1);
                                  }
                                }
                              });
                            }}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    ) : null}

                    {/* Pan card */}
                    {type === "pan" ? (
                      <div
                        className={isSubmitPersonalData == 1 ? "" : "d-none"}
                        id="personal_info"
                      >
                        <div class="steps_div">
                          <div class="d-flex align-items-center">
                            <div class="circle_no active">1</div>
                            <span class="text-lgray">Personal Information</span>
                          </div>
                          <div class="adjacent_line"></div>
                          <div class="d-flex align-items-center">
                            <div class="circle_no">2</div>
                            <span class="text-lgray">Pan Verification</span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-lg-12">
                            <p className="text-danger mb-0 fs-14">
                              {" "}
                              {errorMessage}
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <div className="">
                              <div class="form-group">
                                <label for="annualincome">Annual Income</label>
                                <select
                                  className="form-select"
                                  name="annualincome"
                                  id="incomeRange"
                                  onChange={(e) => {
                                    setAnnualIncome(e.target.value);
                                  }}
                                >
                                  <option selected disabled>
                                    --Select Income Range--
                                  </option>
                                  <option value="Less Than 1,00,000">Less Than 1,00,000</option>
                                  <option value="1,00,000 - 5,00,000">1,00,000 - 5,00,000</option>
                                  <option value="5,00,000 - 10,00,000">
                                    5,00,000 - 10,00,000
                                  </option>
                                  <option value="10,00,000 - 25,00,000">
                                    10,00,000 - 25,00,000
                                  </option>
                                  <option value="Above 25,00,000">Above 25,00,000</option>
                                </select>

                                {/* <input
                                  type="Number"
                                  class="form-control"
                                  id="annualincome"
                                  name="annualincome"
                                  onChange={(e) => {
                                    setAnnualIncome(e.target.value);
                                  }}
                                /> */}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div class="form-group">
                              <label for="jobprofession">Job Profession</label>
                              {/* <input
                                type="text"
                                class="form-control"
                                id="jobprofession"
                                name="jobprofession"
                                onChange={(e) => {
                                  setJobProfession(e.target.value);
                                }}
                              /> */}

                              <select
                                className="form-select"
                                name="jobprofession"
                                id="jobprofession"
                                onChange={(e) => {
                                  setJobProfession(e.target.value);
                                }}
                              >
                                <option selected disabled>
                                  --Select Job Profession--
                                </option>
                                <option value="Software Engineer">Software Engineer</option>
                                <option value="Hardware Engineer">Hardware Engineer</option>
                                <option value="Doctor">Doctor</option>
                                <option value="Teacher">Teacher</option>
                                <option value="Accountants & Auditors">
                                  Accountants & Auditors
                                </option>
                                <option value="Lawyer">Lawyer</option>
                                <option value="Administrative Services Managers">
                                  Administrative Services Managers
                                </option>
                                <option value="Clerk">Clerk</option>
                                <option value="Self Employed">Self Employed</option>
                                <option value="Others">Others</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="id_no">Pan Number</label>
                              <input
                                type="text"
                                className="form-control text-dark"
                                id="id_no"
                                name="id_no"
                                value={id_Pan}
                                onChange={(e) => {
                                  setIdPan(e.target.value.toUpperCase());
                                }}
                              />
                              <button
                                className="btn btn-primary mt-2"
                                disabled={loading}
                                onClick={() => {
                                  if (type === "pan") {
                                    verifyPan();
                                  }
                                }}
                              >
                                {loading ? (
                                  <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                                ) : null}
                                Verify Pan Number
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* <p className="fs-12 text-lgray mb-3">
                          Please read the User Identity Verification Statement
                          and Jumio Privacy Policy to understand how your
                          personal information and biometric data is collected
                          and used for verification purposes. Click the Continue
                          button to indicate that you have read and agree to the
                          statement and policy.
                        </p>
                        <div className="d-grid">
                          <button
                            className={`${
                              verificationSuccess ? "" : "disabled"
                            } btn btn-warning my-2 `}
                            data-bs-dismiss="modal"
                            onClick={(e) => {
                              N_checkKYCStatus(token).then((res) => {
                                if (res.status === 200) {
                                  if (res?.params.status == 2) {
                                    setIsverified(2);
                                    setType("aadhar");
                                  }
                                }
                              });
                            }}
                          >
                            Close
                          </button>
                        </div> */}
                      </div>
                    ) : null}

                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
