import React, { useEffect } from "react";
//import Banner from "./Banner";
import Footer from "./Footer";
import Header from "./Header";
import Card from "./Card";
import AOS from "aos";
import "aos/dist/aos.css";
import FooterNav from "./FooterNav";
import CalltoAction from "./CalltoAction";
import { useSelector } from "react-redux";
import { FcBiotech, FcRules } from "react-icons/fc";
import { Link } from "react-router-dom/cjs/react-router-dom";

export default function About() {
  const { isLoggedIn } = useSelector(
    (state) => state.AuthReducer
  );
  /*  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }); */
  AOS.init({ duration: "2000" });

  return (
    <>
      <Header />
      <div className="page-content">
        <div className="inner_page_banner">
          <div className="container">
            <div className="row align-items-center py-4">
              <div className="col-12 col-lg-8 mb-3">
                <div className="">
                  <h3 className="page_main_heading">
                    Welcome to{" "}
                    <span className="text-highlight"> FRX Exchange </span>
                    with <br /> Empowering Your Crypto Journey
                  </h3>

                  <p className="fs-14 text-lgray">
                    At FRX Exchange, we're passionate about revolutionizing
                    the way people trade and invest in cryptocurrencies. Founded
                    with a vision to provide a secure, user-friendly, and
                    innovative trading platform, FRX is committed to
                    empowering individuals worldwide to participate in the
                    cryptocurrency revolution.
                  </p>

                  {/* {!isLoggedIn?
                  <Link to="/create" className="btn btn-primary text-white">
                    Sign up today !
                  </Link>:null} */}
                  
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <img src="/img/Trading-PNG-Photo.png" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        <section className="spacer pb-3">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="py-4 pe-5">
                  <h4 className=""> <FcBiotech /> Our Mission</h4>
                  <p className="text-lgray">
                    Our mission at FRX Exchange is simple: to democratize
                    access to cryptocurrencies and empower individuals to take
                    control of their financial future. We believe that everyone
                    should have the opportunity to participate in the digital
                    economy, and we're committed to providing the tools,
                    resources, and support needed to make that vision a reality.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="p-4">
                  <h4 className=""><FcRules /> Our Story</h4>
                  <p className="text-lgray">
                    FRX Exchange is a leading cryptocurrency exchange
                    platform designed to meet the evolving needs of traders of
                    all levels. With a team of industry experts and technology
                    enthusiasts, we strive to create an environment where
                    traders can access a wide range of digital assets, execute
                    trades with confidence, and harness the power of blockchain
                    technology.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="spacer">
          <div className="container">
            <div className="row">
              <div className="col-lg order-1 order-lg-1 d-flex flex-column align-items-lg-center justify-content-lg-center">
                <div className="container ">
                  <h3 className="text-center">
                    Why Choose FRX Exchange?
                  </h3>
                  <p className="text-center">
                    Here are some reasons why you should choose FRX Exchange
                    for your cryptocurrency trading needs
                  </p>

                  <div className="row process_wrapper">
                    <div className="col-lg-4 mb-4">
                      <div className="about_img">
                        <img src="/img/add-user.png" className="img-fluid" />
                      </div>
                      <h4>Security First</h4>
                      <p className="text-lgray">
                        Your security is our top priority. FRX Exchange
                        employs advanced security measures, including
                        multi-layer encryption, cold storage, and regular
                        security audits, to ensure the safety of your assets and
                        personal information.
                      </p>
                    </div>
                    <div className="col-lg-4 mb-4">
                      <div className="about_img">
                        <img src="/img/link.png" className="img-fluid" />
                      </div>
                      <h4>Diverse Range of Assets</h4>
                      <p className="text-lgray">
                        FRX Exchange offers a wide range of cryptocurrencies
                        for trading, allowing you to explore new opportunities
                        and diversify your investment portfolio.
                      </p>
                    </div>

                    <div className="col-lg-4 mb-4">
                      <div className="about_img">
                        <img src="/img/savings.png" className="img-fluid" />
                      </div>
                      <h4>Low Fees</h4>
                      <p className="text-lgray">
                        We believe in fair and transparent pricing. FRX
                        Exchange offers competitive trading fees and no hidden
                        charges, ensuring that you get the best value for your
                        money.
                      </p>
                    </div>

                    <div className="col-lg-4 mb-4">
                      <div className="about_img">
                        <img src="/img/chart.png" className="img-fluid" />
                      </div>
                      <h4>Advanced Trading Tools</h4>
                      <p className="text-lgray">
                        Gain access to a suite of advanced trading tools and
                        features on FRX Exchange, including real-time market
                        data, charting tools, and customizable trading
                        strategies.
                      </p>
                    </div>
                    <div className="col-lg-4 mb-4">
                      <div className="about_img">
                        <img src="/img/chart.png" className="img-fluid" />
                      </div>
                      <h4>Responsive Customer Support</h4>
                      <p className="text-lgray">
                        Our dedicated customer support team is available 24/7 to
                        assist you with any questions or issues you may have.
                        Whether you need help navigating the platform or have a
                        technical inquiry, we're here to help.
                      </p>
                    </div>
                    <div className="col-lg-4 mb-4">
                      <div className="about_img">
                        <img src="/img/chart.png" className="img-fluid" />
                      </div>
                      <h4>Community Engagement</h4>
                      <p className="text-lgray">
                        Join a vibrant and supportive community of traders on
                        FRX Exchange. Connect with fellow enthusiasts, share
                        insights, and collaborate on trading strategies to
                        maximize your success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="about_cta">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-lg-9">
              <div className="about_cta_content">
                <h3>Ready to Get Started? </h3>
                <p>
                  Sign up now to access our platform and start trading
                  cryptocurrencies with confidence.
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <Link to="/create" class="btn btn-light btn-lg">
                Sign Up to FRX Exchange
              </Link>
            </div>
          </div>
        </div>
      </div> */}
      </div>
      <CalltoAction />
      <FooterNav className="spacer" />
      <Footer />
    </>
  );
}
